.table-wrapper {
  @include media-breakpoint-down(sm) {
    white-space: normal;
    overflow-x: auto;
  }
}

.client {
  &__table {
    width: 100%;
    color: #fff;
    max-width: 900px;
    margin: 20px auto 30px auto;
    clear: both;

    @include media-breakpoint-down(sm) {
      margin: 30px auto 0;
    }

    th.cell {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 300;
      padding: 5px 0;
      border-top: 2px solid #779DBD;
      border-bottom: 2px solid #779DBD;
      border-left: 1px solid #779DBD;
      border-right: 1px solid #779DBD;
      font-family: $additionalFont;
      min-width: 135px;

      @include media-breakpoint-down(sm) {
        border: none;
        color: #6ca4d1;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        padding: 5px;
      }

      &:first-child {
        border-left: 2px solid #779DBD;

        @include media-breakpoint-down(sm) {
          border-left: none;
        }
      }

      &:last-child{
        border-right: 2px solid #779DBD;

        @include media-breakpoint-down(sm) {
          border-right: none;
        }
      }

      &--amount,
      &--cost {
        @include media-breakpoint-down(sm) {
          text-align: right;
        }
      }
    }

    td.cell {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 300;
      padding: 10px 0;
      font-family: $additionalFont;
      border-bottom: 1px solid #779DBD;
      border-right: 1px solid #779DBD;

      @include media-breakpoint-down(sm) {
        border: none;
        font-size: 13px;
        font-weight: 500;
        padding: 10px 5px;
        text-align: left;

        .payments-time {
          color: #8c8d8e;
          letter-spacing: .2px;
        }
      }

      .pending{
        color: #6999BB;
      }

      .approved{
        color: #ffffff;
      }

      .rejected {
        color: #ff396e;
      }

      &:last-child{
        border-right: none;
      }

      &--amount,
      &--cost {
        @include media-breakpoint-down(sm) {
          text-align: right;
        }
      }
    }

    .custom__row {
      &:last-child{
        td.cell{
          border-bottom: none;
        }
      }

      &:nth-child(even){
        td.cell{
          background-color: #26303C;

          @include media-breakpoint-down(sm) {
            background-color: transparent;
          }
        }
      }

      &:nth-child(odd){
        td.cell {
          @include media-breakpoint-down(sm) {
            background-color: rgba(#ffffff, .1);
          }
        }
      }
    }
  }
}
