 // Utils
@import 'utils/variables';
@import 'utils/mixins';
@import 'utils/breakpoints';
@import 'utils/functions';
@import 'utils/spacing';

// Basic
@import 'basic/global';

// Layouts
@import 'layouts/container';
@import 'layouts/row';

// Modules
@import 'modules/fixed-header';
@import 'modules/menu';
@import 'modules/social-buttons';
@import 'modules/deep-token';
@import 'modules/svg-icons';
@import 'modules/sections';
@import 'modules/project';
@import 'modules/timer';
@import 'modules/exchange';
@import 'modules/vision';
@import 'modules/corner';
@import 'modules/slick-pagination';
@import 'modules/history-slider';
@import 'modules/coverage-slider';
@import 'modules/status';
@import 'modules/user';
@import 'modules/ended';
@import 'modules/partners';
@import 'modules/errors';
@import 'modules/inform';
@import 'modules/roadmap';
@import 'modules/team';
@import 'modules/custom-tabs';
@import 'modules/cheme';
@import 'modules/settings';
@import 'modules/inner-calculator';
@import 'modules/steps';
@import 'modules/design-blocks';
@import 'modules/ico-status';
@import 'modules/purchase';
@import 'modules/radio';
@import 'modules/client-table';
@import 'modules/terms';
@import 'modules/not-found';
@import 'modules/pre-info';

// Includes
@import 'includes/buttons';
@import 'includes/modals';
@import 'includes/forms';
@import 'includes/placeholder';

// Partials
@import 'partials/header';
@import 'partials/footer';


.flex_item {
  @include flexible(flex-start, center, row);

  &.text-right {
    display: flex;
    flex-direction: column!important;
    padding-top: 0;
    align-items: flex-end !important;

    @include media-breakpoint-up(lg) {
      //@include flexible(flex-end, center, row);
      padding: 15px 0 0 0;
      align-items: flex-end;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sign__wrapper{
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.flex_item{
  @include flexible(flex-start, center, row);
  padding: 0;
  margin-bottom: 0;
  &.text-right{
      @include flexible(flex-end, center, row);

    @include media-breakpoint-up(lg) {
      padding: 15px 0 0 0;
    }
  }
}

/* Titles */
  .title-md{
    font-size: 25px;
    color: #fff;
    font-family: "Teko",sans-serif;
    line-height: 1.2;
    margin-bottom: 14px;
    @include media-breakpoint-up(md) {
      font-size: 27px;
      margin-bottom: 10px;
      font-size: 36px;
    }
  }

/* Lists && paragraphs with styles */
  .p-left-brd{
    font-family: "Kanit",sans-serif;
    font-weight: 300;
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 20px;
      border-left: 2px solid #7ebcec;
      padding: 0 15px 0 15px;
    }
  }

  .c-list{
    list-style: none;
    margin-bottom:10px;
    padding-left: 0;
    font-family: "Kanit",sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.3;
    color: #fff;
    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 20px;
    }
    &_square{
      li{
        position: relative;
        padding-left: 17px;
        &:after{
          content: "";
          display:block;
          position: absolute;
          left: 0;
          top: 7px;
          height: 6px;
          width: 6px;
          background-color: #7ebcec;
        }

        &:not(:last-child){
          padding-bottom: 28px;
          @include media-breakpoint-up(md) {
            padding-bottom: 20px;
          }
        }
      }
    }

  }



.user_name {
  font-size: 14px;
  padding-left: 6px;
  text-align: left;
  line-height: 25px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  margin-right: 6px;
  transition: all linear 0.3s;

  @include media-breakpoint-down(md) {
    color: #ffffff;
    text-align: left!important;
    font-size: 20px;
    font-weight: 600;
    margin-left: 7px;
  }

  @include media-breakpoint-up(lg) {
    min-width: 80px;
  }

  @media (min-width: 1400px) {
    min-width: auto;
  }
}

.fa {
  transition: all linear 0.3s;
}
.auth__wrapper{
  width: 100%;
  z-index: 99;
  @media screen and (max-width: 991px){
    left: 15px;
  }
  .reg_item{
    @include flexible(center, center, column);
    transition: all 0.5s linear;
    @media screen and (max-width: 1200px){
      bottom: 0;
      right: 189px;
    }
    @media screen and (max-width: 991px){
      bottom: 0;
      right: auto;
    }
    @media screen and (max-width: 768px) {
      margin-left: 5px;
    }
  }
}
.fa {
  transition: all linear 0.3s;
}

.auth__wrapper {
  width: 100%;
  z-index: 99;
  @media screen and (max-width: 991px) {
    left: 15px;
  }
  .reg_item {
    @include flexible(center, center, column);
    transition: all 0.5s linear;
    @media screen and (max-width: 1200px) {
      bottom: 0;
      right: 189px;
    }
    @media screen and (max-width: 991px) {
      bottom: 0;
      right: auto;
    }
    @media screen and (max-width: 767px) {
      bottom: 0;
    }
  }
}
.reg_item{
  .orange_button:hover{
    transform: scale(1.05);
  }
}
.relative{
  position: relative;
}
.section_item{
    .section_title{
        font-size: 72px;
        letter-spacing: 3px;
        color: rgb(255,255,255);
        font-family: $mainFont;
        text-transform: uppercase;
        position: relative;
        text-shadow: 0px 3px 0px rgba(27,60,89,1);
        position: relative;
        &:after{
          content: " ";
          display: inline-block;
          width: 100%;
          height: 30px;

          position: absolute;
          top: 0;
          left: 0;
        }
        &.deepToken__title{
          font-size: 60px;
          color: rgb(233,223,178);
          text-shadow: 0px 3px 7px rgba(0,0,0,0.15);
          @media screen and (max-width: 767px){
           font-size: 38px;
          }
          .conter{
            color: #fff;
            .counter__value{
              font-size: 110px;
            }
          }
        }
    }
    .section_alias{
        font-size: 23px;
        letter-spacing: 2px;
        color: rgb(127,189,237);
        font-family: $additionalFont;
    }
    .section_text{
        max-width: 88%;
        padding-left: 10px;
        border-left: 3px solid #7FBDED;
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        font-family: $additionalFont;
        @media screen and (max-width: 767px){
          font-size: 14px;
        }
        span{
            font-weight: 400;
        }
        @media screen and (max-width: 767px){
            max-width: 80%;
        }
    }
    .orange_button{
        @media screen and (max-width: 480px){
            margin-right: 0;
            margin-bottom: 15px;
            font-size: 26px;
            height: 56px;
            text-align: center;
            width: 85%;
            font-weight: 600;
        }
        &.big_button{
          font-size: 45px;
          padding: 9px 0px;
          font-weight: 700;
          border-radius: 5px;
          margin: 40px 2px 0 0;
          min-width: 160px;
        }
    }
    .white_button{
        @media screen and (max-width: 480px){
            margin-bottom: 15px;
        }
    }
}
.vertical_flex{
    @include flexible(flex-start, flex-start, column);
}
.vertical_flex_center{
    @include flexible(center, center, column);
    &.text-left{
        @include flexible(center, flex-start, column);
    }
}
.flex_item_vertical{
    @include flexible(center, center, column);
}
.scale_svg{
    transform: scale(3);
}

.relative {
  position: relative;
}



.section_item {
  .section_title {
    font-size: 72px;
    letter-spacing: 3px;
    color: rgb(255, 255, 255);
    font-family: $mainFont;
    text-transform: uppercase;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: 52px;
      letter-spacing: 2.6px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      letter-spacing: 2.5px;
      line-height: 1.2;
    }

    &.deepToken__title {
      font-size: 52px;
      color: rgb(233, 223, 178);
      text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
      margin-top: 0;
      @media screen and (max-width: 767px) {
        font-size: 38px;
      }

      .conter {
        color: #fff;
        .counter__value {
          font-size: 110px;
        }
      }
    }
    &.flex__title{
      @include flexible(center, flex-start, row);
      @media screen and (min-width: 1200px){
        margin-left: 160px;
        margin-top: -55px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .section_alias {
    font-size: 13px;
    color: white;
    font-family: $additionalFont;
    letter-spacing: normal;
    font-weight: 400;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }

    @include media-breakpoint-up(md) {
      font-size: 21px;
      letter-spacing: 2px;
      font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.3;
    }
  }

  .section_text {
    max-width: 88%;
    padding-left: 10px;
    border-left: 3px solid #7FBDED;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    font-family: $additionalFont;
    font-weight: 300;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }

    span {
      font-weight: 400;
    }

    @media screen and (max-width: 767px) {
      max-width: 80%;
    }

    @media screen and (max-width: 425px) {
      max-width: 100%;
      border-left: none;
      padding-left: 0;
    }

  }

  .section_text_sm{
    font-size: 14px;
    font-weight: 100;
    line-height: 1.3;
    color: #7FBDED;
    font-family: "Kanit",sans-serif;
    @include media-breakpoint-up(sm) {
      padding-top: 14px;
      line-height: 1.2;
      font-size: 18px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 21px;
    }
  }

  .orange_button {
    @media screen and (max-width: 480px) {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &.big_button {
      font-size: 45px;
      padding: 9px 0;
      font-weight: 700;
      border-radius: 5px;
      margin: 40px 2px 0 0;
      min-width: 160px;

      @media screen and (max-width: 991px) {
        margin: 0 2px 0 0;
      }

      @media screen and (max-width: 425px) {
        min-width: calc(100% - 40px );
        font-size: 26px;
      }
    }
  }

  .white_button {
    @media screen and (max-width: 480px) {
      margin-bottom: 15px;
    }
  }
}

.startTimer{
  @include flexible(flex-start, stretch, row);
  border: 2px solid #80bdea;
  padding: 15px 25px;
  margin-top: -17px;
  position: relative;
  margin-left: 15px;
  @media screen and (max-width: 767px) {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding: 10px;
  }
  .startTimer__item{
    font-size: 52px;
    color: rgb(255,255,255);
    font-family: $mainFont;
    text-align: center;
    text-shadow: 0px 3px 7px rgba(0,0,0,0.15);
    @media screen and (max-width: 767px){
      font-size: 38px;
    }
    @include flexible(flex-start, center, column);
    .startTimer__text{
      font-size: 12px;
      font-family: $additionalFont;
      line-height: 25px;
      margin-top: -15px;
    }
    .startTimer__dots{
      font-family: $additionalFont;
      line-height: 40px;
    }
  }
  .left_timer_corner{
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #80bdea;
    position: absolute;
    bottom: -3px;
    left: -7px;
    transform: rotate(45deg);
    &:after{
      content: " ";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 11px solid #07101B;
      position: absolute;
      bottom: -3px;
      left: -11px;
    }
  }
  .right_timer_corner{
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #80bdea;
    position: absolute;
    top: -3px;
    right: -7px;
    transform: rotate(45deg);
    &:after{
      content: " ";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #07101B;
      position: absolute;
      top: -3px;
      right: -11px;
    }
  }
}

.vertical_flex {
  @include flexible(flex-start, flex-start, column);
}

.vertical_flex_center {
  @include flexible(center, center, column);
  &.text-left {
    @include flexible(center, flex-start, column);
  }
}

.flex_item_vertical {
  @include flexible(center, center, column);
}

.scale_svg {
  transform: scale(3);
}

.left_bold__corner {
  width: 10%;
  height: 60%;
  border-left: 3px solid #80BDEB;
  border-bottom: 3px solid #80BDEB;
  position: absolute;
  left: -2px;
  bottom: -1px;
  z-index: 9;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.right_bold__corner {
  width: 75%;
  height: 60%;
  border-right: 3px solid #80BDEB;
  border-top: 3px solid #80BDEB;
  position: absolute;
  right: -1px;
  top: -2px;
  z-index: 9;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.visions_hint{
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: rgb(254,254,254);
  font-family: $additionalFont;
  span{
      font-weight: 400;
  }
}

.visions_link_item {
    @include flexible(flex-end, center, row);
    @include horizontalGrad(rgba(29, 65, 92, 0), #1D415C)
    padding: 0 10px 0 0;
    .visions_link_text{
        padding-right: 7px;
        font-size: 12px;
        color: rgb(217,140,51);
        font-weight: 300;
        .bold_link_text{
            font-size: 18px;
            color: rgb(118,75,26);
            display: block;
            a{
                font-weight: 500;
                color: rgb(118,75,26);
            }
        }
    }
    .visions_link_icon{
        font-size: 42px;
        padding: 8px 11px;
        background: #FFBB64;
        color: #fff;
    }

  @include media-breakpoint-down(md) {
    background: none!important;
    //justify-content: flex-start;
    flex-direction: row-reverse!important;
  }
}


.section_2{
  position: relative;
  z-index: 999;
  background: #07111D;
  overflow: hidden;

  .container{
    position: relative;
    z-index: 9;
  }

  @include media-breakpoint-down(md) {
    background-color: #051323;
  }

  .scroll__btn.scroll__btn--section{
    margin: -20px auto 30px;
    display: block;
  }

}

.visions_hint {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: rgb(254, 254, 254);
  font-family: $additionalFont;
  span {
    font-weight: 400;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    font-weight: 500;

    span { font-weight: 600; }
  }
}

.visions_link_item {
  @include flexible(flex-end, center, row);
  @include horizontalGrad(rgba(29, 65, 92, 0), #1D415C)
  .visions_link_text {
    padding-right: 7px;
    font-size: 12px;
    color: rgb(217, 140, 51);
    font-weight: 300;
    .bold_link_text {
      font-size: 18px;
      color: rgb(118, 75, 26);
      display: block;
      a {
        font-weight: 500;
        color: rgb(118, 75, 26);
      }
    }
  }
  .visions_link_icon {
    font-size: 42px;
    padding: 8px 11px;
    background: #FFBB64;
    color: #fff;
  }
}

#rocket_man {
  position: absolute;
  top: calc(50% - 150px);
  right: 0;
}

#roadmap__wrapper,
#team__wrapper {
  position: relative;

  .scroll__btn {
    position: absolute;
    right: 5%;
    bottom: 75px;
    z-index: 999;

    @include media-breakpoint-down(sm) {
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: 12px;
      display: block;
      max-width: calc(100% - 30px);

      //.fa { display: none; }
    }

    &--section-item {
      right: 0;
      left: 0;
      bottom: 8px;
      margin: 0 auto;
      width: 142px;
    }
  }
}

#team__wrapper {
  .scroll__btn.scroll__btn--section {

    @include media-breakpoint-down(sm) {
      position: static;
    }

  }

}

.scroll__button {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  @include flexible(center, center, row);
  z-index: 9;
}

.scroll__inner {
  @include flexible(center, center, column);
}

.scroll__text {
  font-size: 12px;
  line-height: 25px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  text-transform: uppercase;
  margin-top: 5px;
  display: inline-block;
}

.shadow__wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 95%;
  margin: 0 auto;
  height: 300px;
  @include verticalGrad(rgba(7, 19, 31, 0), rgba(7, 19, 31, 1));
  z-index: 9;
}

.item_line {
  width: 3px;
  background: #7fbded;
  height: 50%;
  position: absolute;
}

.jspHorizontalBar{
  display: none !important;
}
.linkedin_icon{
    width: 42px;
    height: 40px;
    background: url(../img/assets/linkedin.png);
    transition: all linear 0.3s;
}

.title_item {
  font-family: $mainFont;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 4px;
  color: rgb(255,255,255);
  position: relative;
  margin: 56px 0;
  padding: 10px 0 3px 17px;
  text-transform: uppercase;
  &:after{
    content: ' ';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 18px;
    width: 120px;
    height: 2px;
    background: #7ebcec;
  }

  .video_container & {
    margin: 0 0 30px;
    padding-left: 0;
    padding: 10px 0 6px 0;
    letter-spacing: 0.5px;
    font-size: 38px;
    &:after {
      left: 0;
    }

    @include media-breakpoint-down(md) {
      font-size: 32px;
    }

  }

  &.white_title{
      color: #fff;
      &:after{
          @include horizontalGrad(#a7d7ef, rgba(255, 255, 255, 0))
      }
  }

  @include media-breakpoint-down(md) {
    margin: 30px 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    padding: 0;
    margin: 30px 0 15px;
    font-weight: 600;
    letter-spacing: 3px;
    text-align: center;
    &:after { display: none; }
  }
}


.ip__wrapper {
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
}

.partnership__wrapper{
  margin-bottom: 35px;
}

.visions_hint_wrapper{
    padding: 38px 0 97px 0;
    position: relative;
    z-index: 9;
    .container:after{
        display: none;
    }
}

.visions_hint_wrapper {
  padding: 80px 0 57px 0;
  position: relative;
  z-index: 9;

  .container:after {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 0 30px 0;
  }
}

.graph_alias {
  font-size: 24px;
  line-height: 25px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  text-align: center;
  font-weight: 300;
  margin-top: 45px;
  margin-bottom: 22px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  @include media-breakpoint-down(sm) {
    text-align: left!important;
  }
}

// .section_5 {
//   position: relative;
//   overflow: hidden;
//   background: #07111D;

//   @include media-breakpoint-down(md) {
//     background-color: #051323;
//   }

//   .container {
//       position: relative;
//   }
// }


.scroll__wrapper{


  height: 1020px;//550
  overflow: auto;
  transition: all linear 0.3s;
  position: relative;
  z-index: 9;
  max-width: calc(100% - 80px);
  margin: 0 auto;

  //@media screen and (max-width: 992px) {
  //  height: auto;
  //}

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: 100%!important;
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    height: 1120px;
  }

}

#team{
  &.scroll__wrapper{
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }
  &.show-content{
    height: auto;
  }
}

.jspVerticalBar {
  width: 1px;
  background: #fff;
  opacity: 1;
  right: 0;
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}

.indent_title {
  padding-left: 50px;
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
}

.jspDrag {
  width: 5px;
  background: #fff;
  left: -2px;
}

#bg_3 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.team_alias {
  font-size: 14px;
  font-weight: 300;
}

#toggleSchemeLink.open {
  .fa {
    transform: rotateX(180deg);
  }
}

.toggleScheme {
  padding: 0;
  overflow: hidden;
  height: 0;
  img {
    max-width: 95%;
  }
  &.open {
    padding-bottom: 75px;
  }
}

.redirectWhitepaper {
  cursor: pointer;
  transition: all linear 0.3s;



  &:hover {
    box-shadow: 0 3px 5px 1px rgba(23, 22, 22, 0.3);
    a {
      text-decoration: none;
    }
  }

  .header & {
    @include media-breakpoint-down(md) {
      position: absolute;
      top: -11px;
      right: 45px;
      font-size: 18px;
      font-family: $additionalFont;
      white-space: nowrap;

      img {
        margin-right: 9px;
      }

      a {
        color: #ffffff;
      }
    }

    @media (max-width: 340px) {
      font-size: 15px;
      top: -8px;
    }
  }
}

.auth_main_wrapper {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-bottom: 100px;
  background: url(../img/assets/vision_and_others.jpg);
  background-size: cover;

  @include media-breakpoint-down(md) {
    padding-bottom: 85px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 65px;
    //overflow: auto;
    //height: auto;
    //min-height: auto;
    background: #051323;
  }
}

.video__control{
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  @include flexible(center, center, row);
  .fa{
    font-size: 126px;
    color: rgb(126,188,236);
  }
}

// .ytp-large-play-button { position:absolute; left:0; right:0; top:0; bottom: 0; z-index:3; }
.ytp-large-play-button { display: none!important; }

.video {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content:space-between;
  }
}

.video_container {
  width: 100%;
  margin: 0 auto;


  @include media-breakpoint-up(md) {
    width: 80%;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    max-width: 33.33333%;
  }

  &:not(:last-child){
    @include media-breakpoint-up(lg) {
      margin-right: 30px;
    }
  }

  & + .video_container {
     margin-top: 30px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .btn--play{
    width: 65px;
    height: 65px;
    left: 52%;
    &:after{
      border-width: 30px 0 34px 55px;
    }
    @include media-breakpoint-down(sm) {
      top: 50%;
    }
  }

}

.video_wrapper {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  box-shadow: 0 4px 0 0 rgba(0,0,0,.2);

  video,
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  //iframe{
  //  width: 100%;
  //  height: 100%;
  //}
    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
      pointer-events: none;

      iframe .ytp-cued-thumbnail-overlay {
        .ytp-large-play-button {
          display: none!important;
        }
      }
    }
}




.auth-steps__wrapper,
.inner__header{
  background: $greyBg;
  border-bottom: 1px solid #fff;
  min-height: 80px;

  @include media-breakpoint-down(md) {
    min-height: auto;
  }
}

.auth__title{
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
  color: rgb(255,255,255);
}

.auth-steps__wrapper,
.inner__header {
  background-color: $greyBg;
  border-bottom: 1px solid #fff;

  @include media-breakpoint-down(md) {
    border-bottom: none;
  }

  @include media-breakpoint-down(sm) {
    background-color: #102e4b;
  }
}

.auth__title {
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
  color: rgb(255, 255, 255);
  font-family: $mainFont;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
}

.settings-forms {
  .btn-wrapper {
    max-width: 360px;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
      max-width: 400px;;
    }

    .btn {
      width: 100%;
      max-width: 300px;

      @include media-breakpoint-down(md) {
        max-width: calc(100% - 40px);
      }

      @include media-breakpoint-down(sm) {
        max-width: calc(100% - 30px);
      }
    }
  }
}

.images-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  .image__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    position: relative !important;
    overflow: hidden;
    &:hover{
      .image__control{
        bottom: 0;
      }
    }
    .image__item{
      width: 100%;
      max-width: 260px;
    }
    .image__control{
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      bottom: -50px;
      transition: all 0.3s linear;
      @include verticalGrad(rgba(255, 255, 255, 0), #F5F5F5);
      width: 100%;
      cursor: pointer;
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .control__name {
        font-size: 12px;
        color: rgb(60, 60, 60);
      }
      .glyphicon {
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
}

.relative-container {
  position: relative;
}

.inner__container {
  margin: 140px auto 0 auto;
  max-width: 360px;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin: 60px auto 0;
  }
}

.decor__body_right {
  width: 194px;
  height: 560px;
  background: url(../img/assets/deco1.png);
  position: absolute;
  top: 0;
  right: -180px;
  background-position: left bottom;
  background-size: 100%;
  opacity: 0.3;
}

.decor__body_left {
  width: 160px;
  height: 755px;
  background: url(../img/assets/deco2.png);
  background-size: 100%;
  position: absolute;
  bottom: -610px;
  left: 170px;
  transform: rotateY(180deg);
  opacity: 0.2;
}

.letter__icon_wrapper {
  position: relative;
  z-index: 9;
  text-align: center;
  padding: 15px 0 15px 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }


  * {
    position: relative;
    z-index: 9;
  }

  .inner__container {
    &:after {
      top: -100px;
      left: -60px;
    }
  }
}

.form__wrapper {
  position: relative;
  margin-top: 76px;
  margin-bottom: -76px;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 25px;
    max-width: 400px;
    margin: 0 auto;
  }

  &--center {
    @include media-breakpoint-down(md) {
      height: calc(100vh - 160px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .settings-forms & {
        height: auto;
      }
    }

    @include media-breakpoint-down(sm)    {
      height: calc(100vh - 124px);
      align-items: flex-start;
      padding-top: 25px;

      .settings-forms & {
        height: auto;
      }
    }

    .auth-form {
      @include media-breakpoint-down(sm) {
        height: 100%;
        padding-top: 10px;
      }
    }
  }
}

.part__title {
  font-size: 30px;
  letter-spacing: 2px;
  line-height: 36px;
  color: rgb(255, 255, 255);
  font-family: $mainFont;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 300;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: left;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.blocked__info{
  @include flexible(center, center, row);
  font-size: 18px;
  line-height: 25px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  text-align: center;
  text-shadow: 0px 2px 3px rgba(0,0,0,0.75);
  position: absolute;
  bottom: 6px;
  left: 0;
  height: 110px;
  width: 100%;
  text-transform: uppercase;
  z-index: 9;
  font-weight: 300;
}

.deepToken__login {
  font-size: 16px;
  line-height: 18px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-family: $additionalFont;
  padding: 0 24px;
  a {
    font-size: 16px;
    line-height: 18px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: $additionalFont;
  }
  .openModal {
    color: #7fbded;
  }
}

.forgot__link {
  text-decoration: underline;
  font-size: 14px;
  color: rgb(126, 186, 231);
  cursor: pointer;
  font-family: $m__font;
}

.balance__currency {
  font-size: 36px;
  line-height: 25px;
  color: #6fa5ce;
  font-family: $mainFont;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.launching {
  padding: 40px 20px;

  @media screen and (max-width: 992px) {
    padding: 40px 0;
  }

  @media screen and (max-width: 425px) {
    padding: 10px 0;
  }
}

.inner_section {
  .section_item {
    .section_title {
      &.deepToken__title {
        .conter {
          font-size: 72px;
          color: rgb(255, 255, 255);
          text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
        }
      }
    }
    .section_text {
      color: rgb(60, 60, 60);
      border-color: #559dbf;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

.ico__wrapper {
  margin-bottom: -70px;//-170

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}


.text-danger {
  font-size: 14px;
  line-height: 18px;
  color: rgb(200, 72, 65);
  text-transform: uppercase;
  font-family: $additionalFont;
}

.openModal {
  cursor: pointer;
  border-bottom: 1px solid #7fbded;
}


.logged_item {
  position: absolute;
  bottom: 25px;
  right: 210px;
  max-width: 80%;
  @media screen and (max-width: 992px) {
    right: 25px;
  }
}

.logged__controls {
  @include flexible(flex-end, center, row);
  .logged__link {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }
}

.part__hint {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  text-align: center;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

.document__example {
  @include flexible(space-between, center, row);
  width: 100%;
  max-width: 500px;
  margin: 25px auto;

  @include media-breakpoint-down(md) {
    max-width: 460px;
  }

  @include media-breakpoint-down(sm) {
    align-items: flex-start;
    margin: 20px auto 40px;
  }

  .image__zoomer {
    max-width: 236px;
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-top: 12px;
      margin-right: 12px;
    }

    @media (max-width: 375px) {
      max-width: 150px;
      flex-basis: 150px;
    }

    .document__image {
      width: 100%;
    }

    .image__zoom_btn {
      width: 45px;
      height: 45px;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 5px;
      right: 5px;
      @include flexible(center, center, row);
      cursor: pointer;
      transition: all 0.3s linear;
      font-size: 17px;
      color: #fff;
      &:hover {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .rules__title {
    font-size: 12px;
    color: rgb(128, 189, 233);
    font-family: $additionalFont;
    font-weight: 300;
    text-transform: uppercase;
  }
  .rules__list {
    list-style: none;
    padding: 0;
    margin: 0;
    list-style-image: url('../img/assets/list.png');
    padding-left: 28px;

    @include media-breakpoint-down(md) {
      padding-left: 20px;
    }

    .rules__list_item {
      font-size: 14px;
      line-height: 24px;
      color: rgb(255, 255, 255);
      font-family: $additionalFont;
      font-weight: 300;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}






.no-width-limit {
  .flex-group {
    @include flexible(space-between, center, row);
    max-width: 750px;
    margin: 0 auto;
    width: 100%;

    @include media-breakpoint-down(sm) {
      @include flexible(center, center, column);
    }
  }

  .flex-buttons {
    width: 100%;
    max-width: 359px;
    margin: 0 auto;
  }
}

.uploaded__fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
  background-image: url(../img/label/bg.png);
  background-size: 100% 100%;
  display: none;
  opacity: 0;
  .uploaded__text {
    font-size: 24px;
    letter-spacing: 2px;
    color: rgb(255, 255, 255);
    font-family: $mainFont;
    text-transform: uppercase;
  }
  .fa {
    font-size: 30px;
    letter-spacing: 3px;
  }
}
.change__image_wrap{
  width: 100% !important;
  height: 100%;
  @include flexible(center, flex-end, row);
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 99;
  opacity: 0;
}

.change__image {
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 300;
  cursor: pointer;
}



.verify {
  text-decoration: underline;
}

.purchase__text {
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  padding-left: 15px;
  border-left: 3px solid #539CC2;
  margin-left: 11px;
  margin-top: 9px;
  line-height: 24px;
  margin-bottom: 55px;
  padding-right: 20px;
  font-weight: 300;
  font-family:  $additionalFont;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-left: 0;
    padding-left: 25px;
    padding-right: 0;
    margin-bottom: 15px;
  }


  &.no-margin{
    margin-bottom: 0;
  }
  .blue__hint {
    font-size: 18px;
    color: #80bdea;
    font-family:  $additionalFont;
  }
  .blue__text{
    text-decoration: underline;
  }
}

.readonly__wrapper{
  position: relative;
  input{
    font-size: 18px;
    line-height: 25px;
    color: #80bdea;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: transparent !important;
    font-family:  $additionalFont;
    pointer-events: none;
  }
  .fa{
    font-size: 24px;
    color: #fff;
    display: inline-block;
    position: absolute;
    top: 1px;
    right: 4px;
    z-index: 999;
    line-height: 1.2;
  }
}

.eth__purchase_hint {
  font-size: 12px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  font-weight: 300;
  padding-bottom: 30px;
  margin-top: 50px;
  .small{
    font-size: 12px;
    line-height: 25px;
    color: #fff;
    width: 114px;
    height: 30px;
    padding: 0 10px;
  }
  // .right_blue_corner{
  //   top: -33px;
  // }
  .fa{
    color: #80bdea;
  }
  .hint__link a{
    font-size: 12px;
    color: rgb(128,189,234);
    font-family: $additionalFont;
    text-align: center;
    text-decoration: underline;
  }

  .auth-form & {
    margin-top: 10px;
  }
}

.dibs__table_wrapper{
  margin: 0 auto 25px auto;
  max-width: 250px;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.dibs__table{
  width: 100%;
  .table__name{
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(128,189,234);
    font-family: $additionalFont;
    font-weight: 300;
  }
  .tabel__summ{
    text-transform: uppercase;
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(128,189,234);
    font-family: $additionalFont;
    &.total__table_summ{
      color: #21688A;
    }
  }
}

.dibs__table__cell{
  border-bottom: 1px solid #75ADD7;
  padding: 7px 0 0 0;
  &.total_cell{
    border-bottom-color: #fff;
    width: 50%;
    .table__name,
    .tabel__summ{
      color: #fff;
    }
  }
}

.token_schems{
  margin: 50px 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: 35px;
  }
}

.token_schems__item {
  @include flexible(flex-start, center, row);

  @include media-breakpoint-down(sm) {
    flex-direction: column !important;
  }
}

.reverse__flex {
  flex-direction: row-reverse;

  .cheme__list_item {
    text-align: right;
    &:before { display: none; }

    //@include media-breakpoint-down(sm) {
    //  text-align: left;
    //}
  }
}


.animate__bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexible(center, center, row);

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.description {
  font-size: 18px;
  line-height: 30px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    margin-top: 0;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
    font-weight: 400;
  }
}

.collapse__list {
  padding: 0 25px;
  margin: 0;
  list-style: none;
  list-style-image: url('../img/assets/list.png');
  .collapse__list__item{
    span.float{
      float: right;
    }
    margin-bottom: 15px;
  }
}

.calculator__form{
  padding: 20px 30px;
  @include flexible(space-between, center, row);

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  .right_blue_corner{
    top: -4px;
  }

  .calculator__form__row{
    @include flexible(space-between, center, row);
  }
}

.invest__input {
  margin-right: 15px;

  input {
    width: 360px;
    height: 30px;

    @include media-breakpoint-down(sm) {
      width: 210px;
      height: 40px;
    }
  }

}
.invest__select{
  display: inline-block;
  margin-left: 5px;
  select{
    width: 75px;
    padding: 0 10px;
  }
}

.invest__hint,
.calculator__result {
  font-size: 14px;
  color: rgb(123, 182, 225);
  font-family: $additionalFont;
  text-transform: uppercase;
  font-weight: 300;

  @include media-breakpoint-down(sm) {
    font-weight: 400;
    font-size: 12px;
    display: block;
    text-align: left;
  }
}

.calculator__result {
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

input#wallet[readonly="readonly"],
input#userName[readonly="readonly"],
input#userLastName[readonly="readonly"]{
  background: #000;
  pointer-events: none;
  opacity: 0.5;
  +.blue__corners{
    opacity: 0.5;
  }
}

.form__title {
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
  color: rgb(255,255,255);
  font-family: $mainFont;
  text-transform: uppercase;
  font-weight: 400;

  .settings-forms & {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

.purchase_modal__title {
  font-size: 14px;
  color: rgb(128,189,234);
  font-family: $additionalFont;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    letter-spacing: 1px;
    text-align: left;
  }
}

.new_modal_title+.purchase_modal__title{
  margin-top: 40px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    text-align: left;
    font-size: 12px;
  }
}

.purchase_summ {
  font-size: 36px;
  font-family: $mainFont;
  color: #fff;
  letter-spacing: 2px;
  line-height: 36px;
  margin: 15px 0;

  @include media-breakpoint-down(sm) {
    margin: 0 0;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    letter-spacing: 1px;
  }

  .purchase__currency {
    color: #7db9e5;

    @include media-breakpoint-down(sm) {
      font-weight: 400;
    }
  }
}

.blue__text,
.blue__hint{
  color: #7db9e5;
}

#purchase_modal,
#usd_modal{
  .modal_window{
    background-image: url(../img/assets/modals/circuit4.jpg);
    background-repeat: no-repeat;
    background-color: #122232;
    background-position: bottom left;

    @include media-breakpoint-down(sm) {
      background-image: none;
    }
  }
}

.big__summ_modal {
  padding-bottom: 30px;

  @include media-breakpoint-down(sm) {
    height: calc(100vh - 25px);
  }

  .purchase__text {
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      font-family: $additionalFont;
      margin-top: 25px;
    }
  }
}
.card__purchase{
  padding-bottom: 30px;
}
.bank__purchase{
  padding-bottom: 30px;
  .dibs__table_wrapper{
    max-width: 95%;
  }
}
.bank__inform{
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  font-family: $additionalFont;
  margin: 0 -15px;

  @include media-breakpoint-down(sm) {
    text-align: left;
    max-width: 380px;
    margin: 0 auto 35px auto;
  }
}

.text-info{
  text-transform: uppercase;
  font-family: $additionalFont;
  font-size: 16px;
}
.form-group+.text-info{
  margin-top: -32px;
}

 .border_1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }

.border_2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.pay_options{
  float: left;
  opacity: 0.3;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.scroll_block{
  position: relative;
}
.right_blocker{
  position: absolute;
  top: 0;
  right: 50px;
  height: 100%;
  width: calc(100vw - 1570px);
  z-index: 9;
}
.left_blocker{
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: calc(100vw - 1520px);
  z-index: 9;
}

#account-app{
  .timer__icon{
    .timer{
      padding-top: 24px;
    }
  }
}

.no-padding{
  padding: 0;
}

.done__title {
  font-size: 36px;
  color: rgb(255,255,255);
  font-family:$mainFont;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: 1.33px;
    margin-bottom: 20px;
  }
}

.done_blue_text {
  font-size: 18px;
  line-height: 30px;
  color: rgb(127,189,237);
  font-family: $additionalFont;
  margin: 15px 0;
}

.done__text{
  font-size: 18px;
  line-height: 30px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  margin: 15px 0;
}

.big_inform_icon {
  font-size: 96px;
  line-height: 30px;
  color: rgb(127,189,237);
  margin: 50px 0;

  @include media-breakpoint-down(sm) {
    font-size: 48px;
    margin: 50px 0 35px;
  }
}
#auth-app{
  .auth-form{
    margin-top: 25px;
  }
}
.wallet_hint{
  @include flexible(space-between, center, row);
  margin-bottom: 10px;
  .form-group.invest__select{
    margin-bottom: 0;
    margin-left: 7px;
  }
  p{
    margin-bottom: 0;
  }
}
.table-wrapper{
  max-width: 900px;
  margin: 30px auto 0 auto;
  position: relative;
}
.flex__inputs{
  @include flexible(space-between, center, row);
  .form-group{
    width: 45%;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    .form-group{
      width: 100%;
      max-width: 400px;
    }
  }
}
/* Main FAQ */
// fast fix
#faq .responsive_btn span+svg {
  @media screen and (min-width: 992px) and (max-width: 1200px){
    max-width: 138px;
  }
}
#roadmap{
  margin-bottom: 50px;
}
.deeptoken__wrapper{
  @include flexible(space-between, flex-start, row);
  @include media-breakpoint-down(lg) {
    @include flexible(center, center, column);
  }
  &.column{
    flex-direction: column;
  }
}

/* Main IPX */
.ip_item{
  padding-top: 47px;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 0;
    display: block;
  }
}
.ip_image{
  flex-shrink: 0;
  text-align: center;
   // @include media-breakpoint-up(lg) {
   //  text-align: right;
   // }
   img{
    @include media-breakpoint-up(lg) {
      max-height: 124px;
    }
   }
}
.ip_txt-wrapper{
  padding-top: 47px;
  .col-md-4{
    &:first-child{
      .ip_item{
        padding-top: 0;
      }
      .ip_txt{
        @include media-breakpoint-up(sm) {
          padding-top: 15px;
        }
        @media (min-width: 992px){
          padding-top: 32px;
        }
      }
    }
  }
}
.ip_txt{
  padding-top: 15px;
  text-align:center;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-family: "Kanit",sans-serif;
  font-weight: 300;
  margin: 0;
  @include media-breakpoint-up(sm) {
    padding-top: 0;
    text-align:left;
    padding-left: 30px;
  }
  @media (min-width: 992px){
    padding-top: 32px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Benefits */
.section_10{
  background-repeat: no-repeat;
  background-position: left bottom;
  background-color: #122232;
  @include media-breakpoint-up(md) {
    padding-bottom: 51px;
    background-image: url(../img/bg/benefits_bg.jpg);
  }
}

.benefits__col{
  &:first-child{
    .benefits__item{
      &:first-child{
        padding-top: 0;
      }
    }
  }

  .benefits__item{
    &:first-child{
      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }
  }
}

.benefits__item{
  padding-top: 24px;
  .title-md{
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
  @include media-breakpoint-up(md) {
      padding-top: 48px;
  }
}

/* Bonuses */
.section_11{
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #122232;
   padding-bottom: 30px;
  @include media-breakpoint-up(md) {
    padding-bottom: 66px;
    background-image: url(../../img/assets/shemes_and_history.jpg);
  }
}

.bonuses-wrapper{

  .description{
    @include media-breakpoint-up(md) {
      padding-bottom: 35px;
    }
    p{
      &:not(:last-child){
        margin-bottom: 30px;
      }
    }
  }

  .bonuses__col + .bonuses__col{
    margin-top: 25px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

}



.bonuses{
  &__footer{
    padding-top: 22px;
    font-size: 16px;
    font-weight: 100;
    max-width: 555px;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
      max-width: none;
    }
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.bonuses-item-wrapper{
  @include media-breakpoint-up(md) {
    position: relative;
    margin: 0 auto;
    padding: 15px 15px 15px 31px;
    border: 2px solid #539CC2;
  }

  .right-corner--lg{
    display:none;
    width: 46px;
    height: 46px;
    &:before{
      height: 66px;
      left: 44px;
    }
    &:after{
       background: linear-gradient(to bottom, rgba(248,80,50,0) 0%,rgba(248,80,50,0) 90%,#07111D 90%,#07111D 100%),linear-gradient(to right, rgba(248,80,50,0) 0%,rgba(248,80,50,0) 90%,#07111D 90%,#07111D 100%);
    }
    @include media-breakpoint-up(md) {
      display:block;
    }
  }
}

.bonuses-item{
  position: relative;
  &__img{
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__title{
    
    padding-top: 13px;
    padding-bottom: 25px;
    @include media-breakpoint-up(md) {
      padding-right: 80px;
    }
    &-txt{
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 100;
      font-size: 25px;
      letter-spacing: 6px;
      @include media-breakpoint-up(md) {
        font-size: 32px;
        text-align: left;
      }
    }
    &-add{
      display:block;
      text-align: center;
      @include media-breakpoint-up(md) {
        display:inline;
        text-align: left;
      }

    }
  }

  &__list{
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      padding-right: 13px;
      padding-bottom: 15px;
    }
    li{
      font-weight: 400;
      padding-bottom: 6px;
      border-bottom: 1px solid #539CC2;
      &:not(:first-child){
        padding-top: 33px;
      }
    }
    &_pt20{
      @include media-breakpoint-up(md) {
        padding-top: 20px;
      }
    }

    &-count{
      &_fs{
        &25{
          font-size: 25px;
        }
      }
    }

    &-value{
      float: right;
      font-weight: 400;
      &_fs{
        &22{
          font-size: 22px;
        }
        &26{
          font-size: 26px;
        }
        &27{
          font-size: 27px;
        }
        &32{
          margin-top: -10px;
          font-size: 32px;
          @include media-breakpoint-up(md) {
             margin-top: 0;
          }
        }
      }
      &_long{
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          margin-top: 7px;
        }
      }
    }

    &-value-font-fix{
      font-size: 19px;
    }

  }

}

.bonuses-item-add-info{
  padding-top: 32px;
  font-size: 16px;
  font-weight: 100;
  p{
    line-height: 1.7;
  }

}

/* Coverage */
.section_13{
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #122232;
   padding-bottom: 30px;
  @include media-breakpoint-up(md) {
    padding-bottom: 66px;
    background-image: url(../../img/assets/shemes_and_history.jpg);
  }
}


/* Bootstap add's */
.col-pd-lr10{
  padding-left: 10px;
  padding-right: 10px;
}


.visible-sm-md {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.visible-md-up{
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.visible-sm-up{
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
}

.visible-sm-down{
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.mrt80{
  margin-top: 80px;
}

.pb15{
  padding-bottom: 15px;
}