.left__small__column{
  max-width: 360px;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    @include flexible(center, center, column-reverse);
  }
  .pre-hint{
    font-size: 18px;
    line-height: 30px;
    color: rgb(255,255,255);
    font-family: $additionalFont;
    margin-bottom: 45px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }
  }
  .title_item{
    padding-left: 0;
    margin: 20px 0;
    &:after{
      left: 0;
    }
  }
}

.inner__timer__title{
  font-size: 36px;
  color: rgb(255,255,255);
  font-family: $mainFont;
  text-shadow: 0px 3px 7px rgba(0,0,0,0.15);
  max-width: 140px;
  text-transform: uppercase;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}
.inner__timer{
  @include flexible(space-between, center, row);
  @include media-breakpoint-down(lg) {
    margin: 15px 0;
  }
  @include media-breakpoint-down(sm) {
    @include flexible(center, center, column);
  }
  .startTimer{
    padding: 6px 25px;
    margin-top: 0;
    min-width: 200px;
    justify-content: space-between;
    .startTimer__item{
      .startTimer__dots{
        line-height: 50px;
      }
      .startTimer__text{
        margin-top: -25px;
        @include media-breakpoint-down(sm) {
          margin-top: -15px;
        }
        text-transform: uppercase;
      }
    }
    .left_timer_corner:after{
      border-top: 11px solid #0D1927;
    }
    .right_timer_corner:after{
      border-bottom: 11px solid #0D1927;
    }
  }
}

.right__big__column{
    @include media-breakpoint-down(lg) {
      margin-bottom: 80px;
    }
  .steps-list__item{
    margin-left: 60px;
    padding: 15px 30px;
    margin-bottom: 30px;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 50px;
    }
    &:after{
      content: " ";
      display: none;
    }
    .steps-list__content__text{
      font-size: 18px;
      line-height: 30px;
      color: rgb(255,255,255);
      font-family: $additionalFont;
      font-weight: 300;
    }
  }
  .steps__inner__title{
    font-size: 24px;
    line-height: 30px;
    color: rgb(255,255,255);
    font-family: $additionalFont;
    margin-bottom: 25px;
  }
}
