.close_icon_wrappper {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 9;
  width: auto;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .flex-row {
    @include flexible(center, center, row);
  }
}

.contact_header_item {
  font-size: 14px;
  color: #fff;
  padding: 12px 2px 0;

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 12px;
    flex-direction: column !important;
    align-items: flex-end !important;
  }

  @media screen and (max-width: 768px) {
    margin-left: -15px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    font-size: 12px;
    justify-content: center !important;
    margin-top: 15px;
  }

  .contact_inner {
    position: relative;
    cursor: pointer;
    margin-left: 15px;
    white-space: nowrap;
    transition: all linear 0.3s;

    a.contact_text {
      text-decoration: none;
      border-bottom: none;
    }

    &.openModal {
      text-decoration: none;
      border-bottom: none;
    }

    .contact_text {
      text-transform: capitalize;
      //text-decoration: underline;
      font-size: 20px;
      line-height: 25px;
      color: rgb(255, 255, 255);
      font-family: $additionalFont;
      transition: all linear 0.3s;
      margin-left: 3px;
      border-bottom: none;

      @include media-breakpoint-down(md) {
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: 1.5px;
        border-bottom: none;
        margin-left: 33px;
        font-family: $mainFont;
        text-decoration: none;
      }

      @include media-breakpoint-up(lg) {
        min-width: 80px;
        max-width: 80px;
        text-align: left;
        display: inline-block;
        vertical-align: bottom;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (min-width: 1400px) {
        max-width: 170px;
      }
    }

    .contact_icon {
      display: inline-block;
      margin-right: 5px;
      font-size: 17px;
      position: relative;
      top: 1px;
      color: #80BDEA;
      transition: color linear 0.3s;

      @media screen and (max-width: 991px) {
        font-size: 22px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-right: 0;
        color: $mainColor;
        transform: translateY(-50%);
      }
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
      align-items: center;
      text-align: left;
      padding: 0 0;
      width: 100%;
      margin-left: 0;

      .contact { text-decoration: none; }
    }

    &:hover {
      a { text-decoration: none; }
      .contact_text,
      .fa { color: #6DC7FF; }
      .contact_text { border-color: #6DC7FF; }
    }
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
}


.deeptoken__header {
  background: $headerSticky;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 9;

  @include media-breakpoint-down(md) {
    overflow: visible;
  }

  .flex_container {
    min-height: 93px;

    @include media-breakpoint-down(md) {
      min-height: auto;
    }


    @media screen and (max-width: 768px) {
      display: block;
      //padding-bottom: 30px;
    }
  }
  .contact_header_item {
    padding-top: 0;
    position: relative;
    z-index: 9;
    &.left__item {
      @media screen and (max-width: 768px) {
        position: absolute;
        left: 0;
        text-align: left;
        width: 50%;
      }
    }
    &.right__item {
      @media screen and (max-width: 768px) {
        position: absolute;
        left: 50%;
        text-align: left;
        width: 50%;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      .contact_inner {
        background: transparent;
      }
    }
  }
  .center-logo {
    padding: 20px 0;
  }
  .language__text {
    text-decoration: underline;
  }
  .decor__header {
    background: url(../img/assets/deco1.png);
    position: absolute;
    top: -311px;
    right: 88px;
    width: 228px;
    height: 952px;
    background-size: 100% 100%;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}


.header__item {
  margin-top: 37px;
  width: 100%;
  text-align: center;
  position: relative;
  &:after {
    content: ' ';
    display: inline-block;
    width: 100%;
    max-width: 1381px;
    height: 32px;
    background: url(../img/assets/header_light.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    bottom: 55px;
  }

  @include media-breakpoint-down(md) {
    &:after { display: none; }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}