.social__buttons {
  display: block;
  position: absolute;
  right: 0;
  bottom: 30%;
  z-index: 9;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    background: url(../img/assets/buttons/social_borders.png);
    width: 57px;
    height: 162px;
  }

  &__item {
    @include flexible(center, center, row);
    min-height: 55px;

    a {
      color: #fff;
      font-size: 30px;
      line-height: 1;
      transition: color linear .3s;

      &:hover { color: #7EBCEC; }
    }
  }

  &--menu {
    position: relative;
    bottom: auto;
    margin-top: 25px;

    @media screen and (max-width: 768px) {
      margin-left: -14px;
    }

    .social__buttons__list {
      background: none;
      display: flex;
      flex-wrap: wrap;
      width: auto;
      height: auto;
    }

    .social__buttons__item {
      position: relative;
      width: 44px;
      height: 44px;
      margin-bottom: 10px;
      min-height: auto;
      border: 1px solid $mainColor;
      &:before,
      &:after {
        content: "";
        position: absolute;
      }

      &:first-of-type {
        &:after {
          position: absolute;
          content: "";
          top: -1px;
          left: -1px;
          width: 13px;
          height: 13px;
          background-color: #051323;
        }

        &:before {
          width: 1px;
          height: 19px;
          background-color: $mainColor;
          left: 12px;
          top: -1px;
          z-index: 5;
          transform: rotate(45deg);
          transform-origin: right top;
        }
      }

      &:last-of-type {
        &:after {
          position: absolute;
          content: "";
          bottom: -1px;
          right: -1px;
          width: 13px;
          height: 13px;
          background-color: #051323;
          z-index: 3;
        }

        &:before {
          width: 1px;
          height: 19px;
          background-color: $mainColor;
          right: -1px;
          bottom: -7px;
          z-index: 5;
          transform: rotate(45deg);
          transform-origin: right top;
        }
      }

      & + .social__buttons__item {
        margin-left: 10px;
      }

      .fa {
        color: $mainColor;
        font-size: 22px;
      }
    }
  }
}