.roadmap_container {
  position: relative;
}

.roadmap_item {
  clear: both;
  @include flexible(flex-start, center, row);

  &:nth-child(odd) {
    .half_roadmap {
      text-align: right;
      border-right: 3px solid #7fbded;
      padding-right: 42px;

      &:after {
        right: -7px;
        top: calc(50% - 7px);
        transition: all linear 0.3s;
      }

      &:before {
        right: 5px;;
        top: calc(50% - 2px);
        background: #7fbded;
      }

      @media screen and (max-width: 767px) {
        margin-left: 0;
        text-align: left;
        padding-right: 0;
        padding-left: 12px;
        width: 100%;
        border-right: 0;
        border-left: 3px solid #7fbded;

        &:after {
          right: auto;
          left: 0;
        }

        &:before {
          right: auto;
          left: 0;
        }
      }
    }
  }

  .roadmap__year {
    font-size: 18px;
    line-height: 30px;
    color: rgb(119,181,230);
    font-family: $additionalFont;
    margin: 0 15px;
    transition: all linear 0.3s;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    @include media-breakpoint-down(sm) {
      flex-direction: row;
    }
    .half_roadmap {
      float: right;
      border-left: 3px solid #7fbded;
      position: relative;
      left: -3px;
      padding-left: 42px;
      &:after {
        left: -7px;
        top: calc(50% - 7px);
      }
      &:before {
        left: 5px;;
        top: calc(50% - 2px);
        background: #7fbded;
      }

      @media screen and (max-width: 767px) {
        margin-right: 0;
        text-align: left;
        padding-left: 12px;
        padding-right: 0;
        width: 100%;
        border-left: none;
        left: 0;
        border-right: none;
        border-left: 3px solid #559dbf;

        &:after {
          left: -7px;
          top: calc(50% - 7px);
        }

        &:before { left: -18px; }
      }
    }
  }

  &:last-child {
    .half_roadmap {
      // padding-bottom: 15px;
      border-right: none;
      border-left: none;
      .item_line {
        top: 0;
        right: 0;

        @include media-breakpoint-down(sm) {
          right: auto;
          left: 0;
        }
      }

      &:after   { right: -4px; }
      &:before  { right: 7px; }

      @include media-breakpoint-down(sm) {
        &:after {
          right: auto;
          left: -7px;
        }

        &:before {
          right: auto;
          left: -7px;
        }
      }
    }
  }
  &.right{
    .half_roadmap{
      &:after   { right: 0px; }
      &:before  { left: -3px; }
      .item_line{
        left: 0;
        right: auto;
      }
    }
  }

  &:first-child {
    .half_roadmap {
      padding-bottom: 15px;
      border-right: none;
      border-left: none;
      .item_line {
        top: 50%;
        right: 0;

        @include media-breakpoint-down(sm) {
          right: auto;
          left: 0;
        }
      }

      &:after { right: -4px; }
      &:before { right: 7px; }

      @include media-breakpoint-down(sm) {
        &:before {
          right: auto;
          left: -10px;
        }

        &:after {
          right: auto;
          left: -5px;
        }
      }
    }
  }

  .half_roadmap {
    width: 50%;
    margin: 0;
    padding: 11px 0;
    position: relative;
    font-size: 18px;
    line-height: 25px;
    color: rgb(255, 255, 255);
    font-family: $additionalFont;
    font-weight: 300;
    transition: all linear 0.3s;

    @include media-breakpoint-up(lg) {
      &:hover {
        font-size: 24px;

        & + .roadmap__year {
          font-size: 23px;
          color: #fff;
        }

        &:after {
          transform: scale(1.4);
          background: #fff;
        }
      }
    }

    span {
      font-weight: 500;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    &:after {
      content: " ";
      position: absolute;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
      background-color: #07111d;
      border: 2px solid #7fbded;
    }
    &:before {
      content: " ";
      position: absolute;
      display: inline-block;
      width: 25px;
      height: 2px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}

.roadmap_button {
  padding: 10px 0 135px 0;
  margin-top: -10px;
  position: relative;
  z-index: 999;

  @include media-breakpoint-down(md) {
    padding: 10px 0 70px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 0 45px 0;
  }

  .main_btn {
    background: url(../img/buttons/shadow.png);

    span {
      font-size: 30px;
      text-transform: uppercase;
    }
  }
}

.left_roadmap_decor,
.right_roadmap_decor_1,
.right_roadmap_decor_2 {
  position: absolute;
  background-size: 100% 100%;
}

.left_roadmap_decor {
  width: 218px;
  height: 1003px;
  background-image: url(../img/assets/deco2.png);
  top: 200px;
  left: -70px;
  opacity: 0.4;
}

.right_roadmap_decor_1 {
  width: 299px;
  height: 548px;
  background-image: url(../img/assets/deco3.png);
  top: 6px;
  right: 143px;
  opacity: 0.1;
}

.right_roadmap_decor_2 {
  width: 248px;
  height: 1043px;
  background-image: url(../img/assets/deco1.png);
  bottom: -400px;
  right: 40px;
  opacity: 0.2;
}
