.slick-dots {
  list-style: none;
  padding-left: 0;
  margin-top: 25px;

  li {
    width: 10px;
    display: inline-block;

    & + li { margin-left: 10px; }

    button {
      display: block;
      width: 10px;
      height: 10px;
      font-size: 0;
      background-color: #203951;
      border: none;
      outline: none;
    }

    &.slick-active {
      button { background-color: #6195bf; }
    }
  }
}