#u  ser-svg,
#arrow1-svg,
#arrow2-svg,
#market-svg,
#wallet-svg {
  position: absolute;
}

#error-meessage {
  display: none;
}

#letter-svg {
  width: 194px;
  height: 125px;

  @include media-breakpoint-down(sm) {
    width: 140px;
    height: 90px;
  }
}

#calendar-svg {
  width: 227px;
  height: 193px;
  fill: #fff;

  @media screen and (max-width: 991px) {
    width: 170px;
    height: auto;
  }

  @media screen and (max-width: 425px) {
    width: 100px;
  }


}

#sand_clock {
  width: 178px;
  height: 283px;

  @include media-breakpoint-down(md) {
    height: 210px;
  }

  @include media-breakpoint-down(sm) {
    height: 110px;
    width: 110px;
  }
}

#timer, .timer__container {
  width: 685px;
  height: 197px;

  @include media-breakpoint-down(sm) {
    width: 280px;
    height: auto;
    margin: 0 auto;
    right: 0;
  }
}

.timer__container {
  position: relative;
  margin-top: 45px;
  margin-left: 20px;
}

#timer {
  position: absolute;
  top: 0;
  left: 0;
}

#timer__big,
#timer__big_blocked {
  width: 685px;
  height: 294px;
  position: absolute;
  top: 0;
  left: 0;
}

#timer__big_blocked {
  z-index: 9;
  display: none;
}

#card-svg, #bank-svg {
  width: 200px;
  height: 130px;
}

#m-svg {
  width: 52px;
  height: 52px;
  position: absolute;
  top: -5px;
  left: -48px;
}

#a-svg {
  width: 52px;
  height: 52px;
  position: absolute;
  top: -5px;
  left: -48px;
}

.relative_span {
  position: relative;
  margin-right: 50px;
}

#border_1,
#border_2,
#border_3,
#border_4,
#border_5,
#border_6 {
  width: 1980px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 99;
  position: relative;
}

#border_2,
#border_4,
#border_6 {
  margin: 100px auto -7px auto;
}

#dots,
#dots_3 {
  position: absolute;
  top: 0;
  opacity: 0.3;
  right: 0;
}

#dots_2,
#dots_4 {
  position: absolute;
  bottom: 30px;
  opacity: 0.3;
  left: 30px;
  transform: rotate(180deg);
}

#bg_1 {
  width: 75%;
}
#bg_1_image{
  width: 1427px;
  max-width: 100%;
}

#bg_2 {
  width: 100%;
}

#border_6 {
  margin-top: 50px !important;
}


.success-svg {
  @include media-breakpoint-down(sm) {
    max-width: 128px;
    margin: 0 auto 30px;
  }
}
