.cheme__title {
  font-family: $mainFont;
  color: #fff;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 4px;

  @include media-breakpoint-down(sm) {
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      text-transform: uppercase;
      letter-spacing: 1px;
  }
}

.cheme__list {
  font-size: 24px;
  letter-spacing: 2px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  font-weight: 300;
  list-style: none;
  padding: 0;
  margin: 0;

  @include media-breakpoint-down(sm) {
    letter-spacing: 0;
  }

  &--right {
    @include media-breakpoint-down(sm) {
      text-align: right;
    }

    .cheme__list_item {
      justify-content: flex-end;
    }
  }
}

.cheme__list_item {
  font-size: 22px;
  margin-bottom: 15px;
  text-indent: -26px;
  margin-left: 26px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    text-indent: 0;
    margin-left: 0;
    &:before,
    &:after {
      position: relative;
      top: 6px;
      border: none!important;
    }

    &:before { margin-right: 10px; }
    &:after { margin-left: 10px; }
  }
}


  .cheme__list_item:before,
.cheme__list_item:after {
  content: " ";
  display: inline-block;
  width: 15px;
  height: 15px;
  min-width: 15px;
  flex-basis: 15p;
  border: 1px solid #ebebeb;
}

.row-flex{
  .cheme__list_item:after{
    display: none;
  }
}


.square_1:before,
.square_1:after {
  background: #7DBBEB;
}

.square_2:before,
.square_2:after {
  background: #69ADE2;
}

.square_3:before,
.square_3:after {
  background: #4D8FC6;
}

.square_4:before,
.square_4:after {
  background: #3679A8;
}

.square_5:after,
.square_5:before {
  background: #226091;
}

.square_6:after,
.square_6:before {
  background: #255279;
}

.square_7:after,
.square_7:before {
  background: #114169;
}

.square_8:after,
.square_8:before {
  background: #163960;
}
