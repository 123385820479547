.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #101922;
  background-image: url("../../img/bg/bg-full.png");
  background-size: cover;
  background-repeat: no-repeat;

  &__header {
    position: absolute;
    top: 0;
    padding: 10px 0;
  }

  &__img {
    @include media-breakpoint-down(sm) {
      max-width: 284px;
      margin: 0 auto;
    }
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__error {
    color: #80bdea;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    margin-bottom: -50px;
    padding-left: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      font-weight: 500;
      padding-left: 10px;
      margin-bottom: -30px;
    }
  }

  &__title {
    color: #80bdea;
    font-size: 48px;
    font-weight: 400;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 4.8px;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  &__text {
    color: #fefefe;
    font-family: $additionalFont;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      max-width: 260px;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}