html {
  &.menu-open {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }
}

body {
  position: relative;
  font-family: $mainFont;
  overflow-x: hidden;

  &.menu-open {
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: 999;
      pointer-events: none;
      background-color: rgba(#000000, .8);
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-up(lg) {
      &:after {
        display: none;
      }
    }
  }
}

img {
  max-width: 100%;
}


.transition {
  transition: all linear 0.3s;
}

/* Text decoration */
.txt-up{
  text-transform: uppercase;
}

/* Text align */
.txt-tac{
  text-align:center;
}

.txt-left{
  text-align:left;
}

.txt-right{
  text-align: right;
}

/* Text colors */
.txt-color-white{
  color: white;
}

/* Fonts */
.font-light{
  font-family: "Kanit",sans-serif;
}

/* colors */
.color-gen-lite{
  color: #75B0E1
}