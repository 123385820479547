.steps {
  position: relative;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  padding: 30px 15px;
  font-family: $additionalFont;

  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 0;
  }

  @include media-breakpoint-up(xl) {
    max-width: 950px;
  }

  &__title {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 30px;
    }

    @include media-breakpoint-up(xl) {
      position: absolute;
      left: -30px;
      width: 240px;
      text-align: right;
    }
  }
}

.steps-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;


  &__item {
    //counter-increment: step-counter;
    position: relative;
    width: 100%;
    max-width: 620px;
    padding: 15px;
    border: 2px solid #71b5e1;
    margin-bottom: 40px;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &:nth-child(2n) {
      @include media-breakpoint-up(xl) {
        align-self: flex-start;
      }
    }

    &:last-of-type {
      &:after { display: none; }
    }

    // &:before {
    //   content: counter(step-counter);
    //   position: absolute;
    //   font-size: 32px;
    //   top: -40px;
    //   left: 5px;
    //   line-height: 1;
    //   font-family: $mainFont;
    //   text-transform: uppercase;
    //   color: #7ab6e4;
    //   font-weight: 600;
    // }

    @include media-breakpoint-up(md) {
      padding: 20px 30px;
      flex-direction: row;
      margin-bottom: 20px;

      // &:before {
      //   left: -45px;
      //   top: -13px;
      //   font-size: 96px;
      //   font-weight: 400;
      // }

    //   &:after {
    //     position: absolute;
    //     content: "";
    //     left: -34px;
    //     top: 75px;
    //     width: 2px;
    //     height: calc(100% - 40px);
    //     background-color: #7ab6e4;
    //   }
    }

    @include media-breakpoint-up(lg) {
      align-self: flex-end;
      max-width: 650px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      margin-bottom: 60px;

      // &:nth-child(2n) {
      //   &:after {
      //     position: absolute;
      //     display: block;
      //     content: "";
      //     width: 176px;
      //     height: 168px;
      //     left: 110px;
      //     bottom: -102%;
      //     top: auto;
      //     background-image: url("../../img/content/arrow-right.png");
      //     background-color: transparent;
      //     background-size: cover;
      //   }
      // }

      // &:nth-child(odd) {
      //   &:after {
      //     position: absolute;
      //     display: block;
      //     content: "";
      //     width: 176px;
      //     height: 168px;
      //     left: auto;
      //     right: 115px;
      //     bottom: -168px;
      //     top: auto;
      //     background-image: url("../../img/content/arrow-left.png");
      //     background-color: transparent;
      //     background-size: cover;
      //   }
      // }

      // &:last-of-type {
      //   &:after { display: none; }
      // }
    }
  }

  &__img {
    max-width: 70px;
    flex-basis: 70px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-right: 30px;
      min-width: 140px;
      max-width: 140px;
      flex-basis: 140px;
      margin-bottom: 0;
    }
  }

  &__content {
    &__title {
      margin-top: 0;
      margin-bottom: 5px;
      position: relative;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      color: #ffffff;
      font-family: $mainFont;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;

      @include media-breakpoint-up(md) {
        font-size: 48px;
        letter-spacing: 4.8px;
        padding-bottom: 5px;
        &:after {
          content: "";
          position: absolute;
          left: 15px;
          bottom: 0;
          width: 124px;
          height: 2px;
          background-color: #7ab6e4;
        }
      }
    }

    &__text {
      color: rgba(#ffffff, .8);
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        color: #ffffff;
        padding-left: 15px;
      }
    }
  }
}