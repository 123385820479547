.section__slide{
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  font-family: arial, helvetica, sans-serif;
  position: relative;
  color: #dddddd;
  height: 100vh;
  @media screen and (max-width: 992px){
    height: auto;
  }
  &.auto_height{
    height: auto;
    min-height: 100vh;
  }
}
.wrapper_slide,
.slide_with_bg{
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
}

.slide_1{
  height: 100vh;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
  }
}

.slide_2{
  background-image: url("../img/placeholder/bg/section_2.jpg");
  background-position: center;
  background-size: cover;
  height: auto;
  min-height: 100vh;
}
.slide_3{
  background-image: url("../img/placeholder/bg/section_3.jpg");
  background-position: center;
  background-size: cover;
}
.slide_4{
  background-image: url("../img/placeholder/bg/section_4.jpg");
  background-position: center;
  background-size: cover;
}
.slide_5{
  background-image: url("../img/placeholder/bg/section_5.jpg");
  background-position: center;
  background-size: cover;
}
.slide_6{
  background-image: url("../img/placeholder/bg/section_6.jpg");
  background-position: center;
  background-size: cover;
}
.slide_7{
  background-image: url("../img/placeholder/bg/section_7.jpg");
  background-position: center;
  background-size: cover;
}
.slide_8{
  background-image: url("../img/placeholder/bg/section_5.jpg");
  background-position: center;
  background-size: cover;
}
.slide_9{
  background-image: url("../img/placeholder/bg/section_6.jpg");
  background-position: center;
  background-size: cover;
}
.slide_10{
  background-image: url("../img/placeholder/bg/section_7.jpg");
  background-position: center;
  background-size: cover;
}
.slide_11{
  background-image: url("../img/placeholder/bg/section_11.jpg");
  background-position: center;
  background-size: cover;
}
.slide_12{
  background-image: url("../img/placeholder/bg/section_12.jpg");
  background-position: center;
  background-size: cover;
}
.slide_13{
  background-image: url("../img/placeholder/bg/section_13.jpg");
  background-position: center;
  background-size: cover;
}
.slide_14{
  background-image: url("../img/placeholder/bg/section_3.jpg");
  background-position: center;
  background-size: cover;
}
.vision_slide{
  @include flexible(space-between, stretch, row);
  padding: 5vh 5% 7vh 5%;
  min-height: 100vh;
  @media screen and (max-width: 768px){
    flex-wrap: wrap;
  };
  .vision_slide__item{
    @include flexible(flex-start, stretch, column);
    width: 32%;
    @media screen and (max-width: 768px){
      width: 100%;
    };
    .slide__title{
      min-height: 140px;
      @media screen and (max-width: 768px){
        min-height: 0;
        font-size: 30px;
        margin-bottom: 45px;
      }
    }
    .vision_slide__text{
      text-align: center;
      background-image: url("../img/placeholder/assets/bar.png");
      background-size: 100% 100%;
      padding: 40px 30px;
      height: 100%;
    }
  }
}
.slide__title{
  font-family: 'Quantico', sans-serif;
  font-size: 3vw;
  text-transform: uppercase;
  color: #d9d9d9;
  text-align: center;
  @media screen and (max-width: 768px){
    font-size: 35px;
  }
  @media screen and (min-width: 1200px){
    font-size: 40px;
  }
}

.deeptoken{
  font-family: 'Syncopate', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  color: #585858;
  position: absolute;
  bottom: 0;
  left: 5%;
  @media screen and (max-width: 768px){
    position: static;
  }
}
.inner_slide{
  padding: 5vh 5% 7vh 5%;
  position: relative;
  &.left-align{
    .slide__title{
      text-align: left;
    }
  }
  &.halfy{
    max-width: 100%;
    @media screen and (max-width: 768px){
      width: 100%;
      max-width: 100%;
    }
  }
  &.big-halfy{
    max-width: 100%;
    @media screen and (max-width: 768px){
      width: 100%;
      max-width: 100%;
    }
  }
  &.very-big-halfy{
    max-width: 100%;
    @media screen and (max-width: 768px){
      width: 100%;
      max-width: 100%;
    }
  }
  p{
    font-size: 23px;
    margin-bottom: 5vh;
  }
  .slide__title{
    margin-bottom: 3vh
  }
}
.angle_bg{
  width: 160%;
  height: 500vh;
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: -800px;
  left: -100%;
  -webkit-transform: rotateZ(20deg);
  -ms-transform: rotate(20deg);
  transform: rotateZ(20deg);
  @media screen and (max-width: 992px){
    -webkit-transform: rotateZ(0) !important;
    -ms-transform: rotate(0) !important;
    transform: rotateZ(0) !important;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
}
.inner_content{
  position: relative;
  z-index: 9;
  width: 50%;
  @media screen and (max-width: 992px){
    width: 100% !important;
  }
  img{
    max-width: 100%;
    margin-bottom: 60px;
    &.graph__image{
      max-width: 70%;
    }
  }
}
.arrows__graph{
  max-width: 1905px;
  margin: 0 auto;
  padding: 0 5% 70px 5%;
}
.graph__inner{
  position: relative;
  height: 100%;
  min-height: 390px;
  @media screen and (max-width: 992px){
    min-height: 0;
  }
  &.vision{
    min-height: 460px;
    .graph__list_item{
      font-family: 'Aldrich', sans-serif;
      color: #fff;
      position: absolute;
      left: auto;
      bottom: 0;
      width: 100%;
      font-size: 15px;
      @media screen and (max-width: 992px){
        position: static;
        padding: 10px;
        margin: -5vh 5% 5vh 5%;
      }
      .inner__star{
        position: relative;
        @media screen and (max-width: 992px){
          &:before{
            width: 119px !important;
            height: 89px !important;
            left: -100px !important;
            top: -30px !important;
          }
        }
        &:before{
          content: "";
          display: inline-block;
          width: 119px;
          height: 89px;
          background-image: url("../img/placeholder/assets/star.png");
          background-size: 100% 100%;
          position: absolute;
          left: -100px;
          top: -30px;
        }
      }
      &:first-child{
        bottom: -25px;
        right: -5%;
        .inner__star{
          &:before{
            width: 79px;
            height: 49px;
            left: -70px;
            top: -10px
          }
        }
      }
      &:nth-child(2){
        bottom: 18px;
        right: -13%;
        .inner__star{
          &:before{
            width: 119px;
            height: 89px;
            left: -100px;
            top: -26px
          }
        }
      }
      &:nth-child(3){
        bottom: 100px;
        right: -22%;
        .inner__star{
          &:before{
            width: 159px;
            height: 129px;
            left: -140px;
            top: -46px;
          }
        }
      }
      &:nth-child(4){
        bottom: 173px;
        right: -31%;
        .inner__star{
          &:before{
            width: 179px;
            height: 139px;
            left: -160px;
            top: -60px;
          }
        }
      }
      &:nth-child(5){
        bottom: 254px;
        right: -41%;
        .inner__star{
          &:before{
            width: 239px;
            height: 209px;
            left: -190px;
            top: -80px;
          }
        }
      }
      &:nth-child(6){
        bottom: 407px;
        right: -56%;
        .inner__star{
          &:before{
            width: 279px;
            height: 249px;
            left: -240px;
            top: -100px;
          }
        }
      }
    }
  }
  img{
    max-width: 45%;
    position: absolute;
    bottom: 0;
    left: 0;
    @media screen and (max-width: 992px){
      display: none;
    }
  }
}

.graph__list{
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  @media screen and (max-width: 992px){
    position: static;
  }
}
.graph__list_item{
  font-family: 'Aldrich', sans-serif;
  color: #fff;
  position: absolute;
  left: auto;
  bottom: 0;
  width: 100%;
  @media screen and (max-width: 992px){
    position: static;
    padding: 10px;
    margin: -5vh 5% 5vh 5%;
  }
  span{
    display: block;
    text-decoration: underline;
  }
  .inner__star{
    position: relative;
    @media screen and (max-width: 992px){
      &:before{
        width: 119px !important;
        height: 89px !important;
        left: -100px !important;
        top: -30px !important;
      }
    }
    &:before{
      content: "";
      display: inline-block;
      width: 119px;
      height: 89px;
      background-image: url("../img/placeholder/assets/star.png");
      background-size: 100% 100%;
      position: absolute;
      left: -100px;
      top: -30px;
    }
  }
  &:first-child{
    bottom: -25px;
    right: -5%;
    .inner__star{
      &:before{
        width: 79px;
        height: 49px;
        left: -70px;
        top: -10px
      }
    }
  }
  &:nth-child(2){
    bottom: 30px;
    right: -12%;
    .inner__star{
      &:before{
        width: 119px;
        height: 89px;
        left: -100px;
        top: -26px
      }
    }
  }
  &:nth-child(3){
    bottom: 95px;
    right: -21%;
    .inner__star{
      &:before{
        width: 159px;
        height: 129px;
        left: -140px;
        top: -46px;
      }
    }
  }
  &:nth-child(4){
    bottom: 160px;
    right: -30%;
    .inner__star{
      &:before{
        width: 179px;
        height: 139px;
        left: -160px;
        top: -60px;
      }
    }
  }
  &:nth-child(5){
    bottom: 255px;
    right: -40%;
    .inner__star{
      &:before{
        width: 239px;
        height: 209px;
        left: -190px;
        top: -80px;
      }
    }
  }
  &:nth-child(6){
    bottom: 340px;
    right: -52%;
    .inner__star{
      &:before{
        width: 279px;
        height: 249px;
        left: -240px;
        top: -100px;
      }
    }
  }
}

.slide__hint{
  position: absolute;
  font-size: 1vw;
  font-weight: 700;
  right: auto;
  bottom: 85px;
  left: 50%;
  @media screen and (max-width: 992px){
    display: none;
  }
}
.slide__icon{
  width: 700px;
  height: 648px;
  position: relative;
  &.illustr_1{
    background-image: url("../img/placeholder/assets/illustration_1.png");
    background-size: 100% 100%;
    .illustr__text{
      position: absolute;
      top: 240px;
      left: 135px;
    }
  }
  &.illustr_2{
    background-image: url("../img/placeholder/assets/illustration_2.png");
    background-size: 100% 100%;
    height: 567px;
    .illustr__text{
      top: 135px;
      left: auto;
      position: absolute;
      right: 176px;
    }
  }
  &.illustr_3{
    background-image: url("../img/placeholder/assets/illustration_3.png");
    background-size: 100% 100%;
    height: 567px;
    .illustr__text{
      position: absolute;
      top: auto;
      left: 207px;
      bottom: 117px;
    }
  }
}

.illustr__text{
  font-family: 'Aldrich', sans-serif;
  text-align: center;
}

.graph__columns{
  @include flexible(center, center, row);
  @media screen and (max-width: 1200px){
    flex-direction: column;
  }
}
.illustr__list{
  font-family: 'Aldrich', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 27px;
  .illustr__list__item{
    margin-bottom: 15px;
  }
}

.slide__team__list{
  @include flexible(flex-start, stretch, row);
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  max-width: 850px;
  margin-top: -35px;
  .slide__team__item{
    margin-right: 25px;
    margin-bottom: 0px;
    @include flexible(space-between, flex-start, column);
    @media screen and (max-width: 768px){
      margin-right: 0;
    }
    img{
      margin-left: -18px;
      margin-bottom: 0;
      height: 33vh;
      max-height: 280px;
      object-fit: scale-down;
      @media screen and (max-width: 768px){
        margin-left: 0;
      }
    }
  }
}

.custom_sizes{
  position: relative;
  top: 3px;
  height: 275px;
}
.socials__deeptoken{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 89px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}
.socials__deeptoken__list{
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 0;
  @include flexible(flex-end, center, row);
  height: 100%;
  @media screen and (max-width: 768px){
    width: 100%;
    justify-content: center;
  }
}
.socials__deeptoken__item{
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 15px;
  a{
    width: 36px;
    height: 36px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    &.deeptoken_twitter{
          background-image: url(../img/placeholder/social/twitter-white.png);
    }
    &.deeptoken_fb{
          background-image: url(../img/placeholder/social/facebook-white.png);
    }
    &.deeptoken_instagram{
          background-image: url(../img/placeholder/social/instagram-white.png);
    }
    &.deeptoken_reddit{
      font-size: 23px;
      line-height: 1.6;
      color: #fff;
    }
  }
  &:hover{
    transform: rotate(45deg);
  }
}
.inner_content.big_width{
  width: 85%;
}
.inner_content.very_big_width{
  width: 100%;
}
