.section_1 {
  background-image: url("../img/assets/first_block_bg_1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;

  @include media-breakpoint-down(md) {
    background-image: none;
    background-color: #051323;
  }

  .container {
    position: relative;
    z-index: 99;
    min-height: 739px;
    @media screen and (max-width: 991px) {
      min-height: 0;
    }
  }

  &:after {
    content: " ";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include verticalGrad(rgba(86, 157, 192, 1), rgba(38, 106, 138, 1));
    opacity: 1;
    z-index: -1;
  }
}

.section_2 {
  position: relative;
  z-index: 999;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  background-image: url(../img/assets/vision_and_others.jpg);
  background-size: cover;

  @include media-breakpoint-down(sm) {
    background-image: none;
    overflow: visible;
    // height: 640px;
    // padding-bottom: 40px;

    // .scroll__btn {
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   margin: 0 auto;
    //   bottom: 12px;
    //   z-index: 50;
    // }
  }

  // @include media-breakpoint-down(xs) {
  //   height: 638px;
  // }

  // @media (max-width: 340px) {
  //   height: 710px;
  // }

  .title_item {
    margin: 74px 0 43px 0;

    @include media-breakpoint-down(sm) {
      margin: 20px 0;
      padding: 0;
    }
  }

  .container {
    position: relative;
    z-index: 9;
  }
}

.section_3 {
  position: relative;
  background-color: #122232;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    border-top: 2px solid#6ca4d1;
    @include section-divider;
  }

  .container {
    position: relative;
    min-height: 564px;
  }
}

.section_4 {
  background-image: url(../../img/assets/shemes_and_history.jpg);
  background-size: cover;
  box-shadow: 1px 1px 10px 1px #000;

  @include media-breakpoint-down(md) {
    background-image: none;
    background-color: #051323;
  }

  @include media-breakpoint-down(sm) {
    position: relative;
    padding-top: 30px;
    border-top: 2px solid#6ca4d1;
    @include section-divider;
  }
}

.section_5 {
  position: relative;
  overflow: hidden;
  // background: url(../img/assets/vision_and_others.jpg);
  background-size: cover;
  background: #07111D;

  @include media-breakpoint-down(md) {
    background-color: #051323;
  }

  @include media-breakpoint-down(sm) {
    border-top: 2px solid#6ca4d1;
    overflow: visible;
    padding-top: 20px;
    @include section-divider;
  }

  .container {
    position: relative;
  }
}

.section_6 {
  background-image: url(../../img/assets/shemes_and_history.jpg);
  background-size: cover;
  padding: 23px 0;
  border-bottom: 1px solid #fff;

  @include media-breakpoint-down(md) {
    background-image: none;
    background-color: #051323;
  }

  @include media-breakpoint-down(sm) {
    position: relative;
    border-bottom: none;
    border-top: 2px solid#6ca4d1;
    @include section-divider;
  }

  .container {
    padding: 0 30px;

    & > .row {
      overflow: hidden;
    }
  }
}

.section_7 {
  overflow: hidden;
  //background: url(../img/assets/vision_and_others.jpg);
  background-size: cover;
  background: #07111D;
  padding-bottom: 50px;

  @include media-breakpoint-down(md) {
    background-color: #051323;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    overflow: visible;
    padding-bottom: 70px;
    border-top: 2px solid#6ca4d1;
    @include section-divider;
  }

  .team_alias {
    margin: 46px 0 22px 0;
    position: relative;
    z-index: 9;
  }
}

.section_8 {
  background: $greyBg;
  padding: 40px 0 60px 0;
  background-image: url(../img/bg/tech.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;

  @include media-breakpoint-down(md) {
    padding: 10px 0 40px 0;
    //background-image: none;
    //background-color: #051323;
  }

  @include media-breakpoint-down(sm) {
    background-image: none;
    background-color: #051323;
  }
}

.section_9 {
  position: relative;
  background-color: #132333;

  @include media-breakpoint-down(sm) {
    border-top: 2px solid#6ca4d1;
    @include section-divider;
  }
}

.section-instractions,
.section-transactions {
  position: relative;
  background-image: url("../../img/bg/instructions-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(md) {
    background-color: #051323;
    background-image: none;
  }
}

.section-transactions {
  padding: 25px 0;

  @include media-breakpoint-up(md) {
    padding: 45px 0;
  }
}

.section-transactions{
  background-image: url("../../img/borders/block_top.svg");
  background-size: unset;
  background-color: #07111D;
}

.video_section {
  padding: 60px 0;
  background-image: url(../img/assets/video_bg.jpg);
  background-size: cover;
  @include flexible(center, center, row);
  box-shadow: 0px 0 5px 1px #000;
  position: relative;
  z-index: 9;

  @include media-breakpoint-down(md) {
    min-height: auto;
    padding: 30px 0;
    background-image: none;
    background-color: #051323;
    box-shadow: none;
  }
}
