.project {
  &__goals {
    margin-top: 150px;
    @include media-breakpoint-up(md) {
      margin: 30px auto 0 auto;
      max-width: 860px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 50px;
    }
    @include media-breakpoint-down(sm) {
      height: 0;
      visibility: hidden;
      opacity: 0;
    }

    &.show-content{
      @include media-breakpoint-down(sm) {
        height: auto;
        visibility: visible;
        opacity: 1;
      }
    }

    &__item {
      @include flexible(flex-start, center, row);
      padding: 0 50px;
      margin-bottom: 40px;
      @media screen and (max-width: 992px) {
        padding: 0;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .project__goals_text {
          border-right: 2px solid #559dbf;
          border-left: none;
          text-align: right;
          margin-right: 15px;

          @media screen and (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    &_text {
      font-size: 18px;
      line-height: 25px;
      color: rgb(255, 255, 255);
      font-family: $additionalFont;
      border-left: 2px solid #7FBDED;
      padding: 5px 15px 5px 15px;
      text-align: left;
      margin-left: 15px;
      font-weight: 300;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
        font-size: 16px;
      }
    }

    &_title {
      font-size: 18px;
      line-height: 25px;
      color: rgb(255, 255, 255);
      font-family: $additionalFont;
      text-align: center;
      font-weight: 300;
      letter-spacing: 1px;

      @include media-breakpoint-down(md) {
        text-align: left;
        font-size: 20px;
      }
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 30px;
    }
  }
}

#goal-7-svg{
  margin-top: -12px;
}