// Width and height

.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }

    // Axes
    .#{$abbrev}x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}


// responsive spacing
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $lengths in $spacers {
        $length-x:   map-get($lengths, x);
        $length-y:   map-get($lengths, y);

        .#{$abbrev}-#{$size}-#{$breakpoint} { #{$prop}:        $length-y $length-x !important; } // a = All sides
        .#{$abbrev}t-#{$size}-#{$breakpoint} { #{$prop}-top:    $length-y !important; }
        .#{$abbrev}r-#{$size}-#{$breakpoint} { #{$prop}-right:  $length-x !important; }
        .#{$abbrev}b-#{$size}-#{$breakpoint} { #{$prop}-bottom: $length-y !important; }
        .#{$abbrev}l-#{$size}-#{$breakpoint} { #{$prop}-left:   $length-x !important; }

        // Axes
        .#{$abbrev}x-#{$size}-#{$breakpoint} {
          #{$prop}-right:  $length-x !important;
          #{$prop}-left:   $length-x !important;
        }
        .#{$abbrev}y-#{$size}-#{$breakpoint} {
          #{$prop}-top:    $length-y !important;
          #{$prop}-bottom: $length-y !important;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $lengths in $spacers {
        $length-x:   map-get($lengths, x);
        $length-y:   map-get($lengths, y);

        .#{$abbrev}-#{$size}-#{$breakpoint}-down { #{$prop}:        $length-y $length-x !important; } // a = All sides
        .#{$abbrev}t-#{$size}-#{$breakpoint}-down { #{$prop}-top:    $length-y !important; }
        .#{$abbrev}r-#{$size}-#{$breakpoint}-down { #{$prop}-right:  $length-x !important; }
        .#{$abbrev}b-#{$size}-#{$breakpoint}-down { #{$prop}-bottom: $length-y !important; }
        .#{$abbrev}l-#{$size}-#{$breakpoint}-down { #{$prop}-left:   $length-x !important; }

        // Axes
        .#{$abbrev}x-#{$size}-#{$breakpoint}-down {
          #{$prop}-right:  $length-x !important;
          #{$prop}-left:   $length-x !important;
        }
        .#{$abbrev}y-#{$size}-#{$breakpoint}-down {
          #{$prop}-top:    $length-y !important;
          #{$prop}-bottom: $length-y !important;
        }
      }
    }
  }
}
