.footer {
    background-image: url(../img/assets/footer_bg.jpg);
    background-size: cover;
    color: #fff;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        background-image: none;
        background-color: #051323;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 15px;
    }

    .container{
        position: relative;
        padding-top: 40px;

        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }
    }

    .fa {
      color: rgb(115,176,225);
    }
}

.footer_menu{
    list-style: none;
    padding: 0;
    border-left: 2px solid #73b0e1;
    padding-left: 15px;
    @media screen and (max-width: 768px){
        margin: 0;
    }
}
.footer_menu_item{
    display: block;
    margin-right: 30px;
    margin-bottom: 5px;
    @media screen and (max-width: 768px){
        display: block;
        margin: 0 0 15px 0;
        text-align: center;
        &:last-child{
            margin-left: 0;
        }
    }
}
@media screen and (max-width: 992px){
    .footer_menu_item:last-child{
        margin-left: 0;
    }
}
.footer_menu_link,
.footer_menu_link.redirectWhitepaper{
    font-size: 18px;
    line-height: 25px;
    color: rgb(255,255,255);
    font-family: $additionalFont;
    text-shadow: 0px 5px 7px rgba(0,0,0,0.15);
    transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    font-weight: 300;
    display: inline-block;
    a{
      color: #fff;
      transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &:hover{
        text-decoration: none;
        color: #7cbcef;
        a{
          margin-right: 5px;
        }
    }
}
.footer_link_item{
    @include flexible(center, center, column)
    width: 124px;
    height: 105px;
    @media screen and (max-width: 768px){
        margin: 0 auto 25px auto;
    }
}
.footer_menu_link.redirectWhitepaper{
  padding: 5px 10px 5px 0;
}
.footer_link_icon{
    color: #fff;
    font-size: 40px;
}
.footer_link_text{
    font-size: 12px;
    color: #fff;
    font-family: $additionalFont;
    text-align: center;
    text-transform: uppercase;
    a{
      font-size: 16px;
      display: block;
      color:  #fff;
      font-family: $additionalFont;
      text-align: center;
      text-transform: uppercase;
    }
}

.contact_data {
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
        // text-align: left
        border-left: none;
        display: inline-block;
    }

    @include media-breakpoint-down(sm) {
        display: block;
    }

    p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        font-family: $additionalFont;
        color: #fff;
        font-weight: 300;
        span{
            font-weight: 400;
        }
    }
}
.phone,
.email{
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    font-family: $additionalFont;
    margin-top: 8px;
    transition: all linear 0.3s;

    @include media-breakpoint-down(sm) {
        text-align: left;
    }

    &:hover{
        opacity: 0.7;
    }

    a {
        text-decoration: underline;
        color: #fff;
        font-size: 14px;
        margin-left: 2px;

        @include media-breakpoint-down(md) {
            text-decoration: none;
        }
    }
}

.footer_logo{
    font-size: 12px;
    color: rgb(37,105,137);
    font-weight: 300;
    padding: 16px 0 53px 0;
    display: inline-block;

    @include media-breakpoint-down(md) {
        // text-align: center;
        margin-right: 30px;
        padding: 16px 0 30px 0;    
    }

    @include media-breakpoint-down(sm) {
        padding: 16px 0 10px 0;
        max-width: 120px;
        margin-right: 0;
        // display: block;
    }

    &_mrl25{
        margin-left: 25px;
        // @include media-breakpoint-up(md) {
        //     margin-left: 25px;
        // }
    }
}

.copyright{
    font-size: 12px;
    color: rgb(37,105,137);
    font-weight: 300;
    margin-left: 26px;
    margin-top: 0;
    display: inline-block;
    // position: relative;
    // top: 7px;
    font-family: $additionalFont;
    // padding-right: 103px;
    float: right;


    @include media-breakpoint-down(md) {
        margin-left: 0;
        text-align: center;
        padding-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
        color: #80bce6;
        padding-bottom: 20px;
        top: auto;
    }
}

.scroll_top{
    box-shadow: 2.424px 4.373px 7px rgba(0,0,0,0.15);
    background-color: #256989;
    width: 60px;
    height: 60px;
    @include flexible(center, center, row);
    font-size: 60px;
    color: #fff;
    position: absolute;
    right: 15px;
    bottom: 60px;
    cursor: pointer;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover{
      background-color: #7cbcef;
    }

    @include media-breakpoint-down(md) {
        width: 50px;
        height: 50px;
        bottom: 290px;

        .fa {
            font-size: 50px;
        }
    }
}

.footer_contracts{
  font-size: 24px;
  letter-spacing: 2px;
  color: rgb(255,255,255);
  font-family: $mainFont;
  @include flexible(flex-start, flex-end, row);
  text-transform: uppercase;
  padding: 8px 0 0px 0;//36
  img{
    margin-left: 10px;
  }

  @include media-breakpoint-down(md) {
      padding: 5px 0 0;
  }

  @include media-breakpoint-down(sm) {
      padding: 15px 0 0;
  }

  @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 1.5px;
      font-weight: 500;
      justify-content: flex-start;
      align-items: center;
  }
}

.mobile-footer-wrapper {
    @include media-breakpoint-down(md) {
        display: inline-block;
        margin-left: 50px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }

    &:not(:last-child){
        @include media-breakpoint-down(sm) {
            margin-right: 50px;
        }
    }
}

.footer-title {
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    font-family: $additionalFont;
    text-shadow: 0px 5px 7px rgba(0,0,0,0.15);
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
}


.footer-info-cont{
    padding-right: 108px;
    @include media-breakpoint-up(md) {
        display: inline-block;
        float: right;
        margin-top: -12px;
        padding-right: 108px;
    }
}

.footer-c-links{
    padding-bottom: 11px;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
    }

    .email{
        @include media-breakpoint-up(md) {
             margin-top: 0;
        }
        &:not(:last-child){
            padding-right: 31px;
        }
        a{
            margin-left: 0;
        }
    }
}

