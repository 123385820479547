.close_icon{
  font-size: 40px;
  color: #fff;
}

@media screen and (max-width: 768px){
  .close_icon_wrappper{
      position: absolute;
      top: 15px;
      right: 15px;
  }
}

.modal_window {
  width: 95%;
  max-width: 550px;
  background: #fff;
  margin: 0 auto;
  min-height: 250px;
  padding: 25px 30px 15px 30px;
  box-shadow: 0px 5px 7px rgba(0,0,0,0.25);
  position: relative;
  // overflow: hidden;
  background-image: url(../img/assets/modals/circuit3.jpg);
  background-repeat: no-repeat;
  background-color: #122232;

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100%;
    padding: 0;
    min-height: 100vh;
    background-image: none;
    overflow: hidden;
  }

  &--register {
    height: auto;
    overflow: visible;
  }

  &--no-bg-img{
    background-image: none;
  }

  // * {
  //   position: relative;
  //   z-index: 9;
  // }

  .modal_body {
    height: 90%;
    position: relative;

    @include media-breakpoint-down(sm) {
      min-height: 100vh;
      padding: 25px 15px 0;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
    }
  }

    .modal_header {
      font-size: 36px;
      color: rgb(255,255,255);
      font-family: $mainFont;
      font-weight: 400;
      letter-spacing: 3px;
      line-height: 1;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-size: 32px;
      }

      .modal_hint{
          display: block;
          font-size: 12px;
          color: #787878;
          font-family: $mainFont;
          position: relative;
          top: -5px;
          @media screen and (max-width: 768px){
              font-size: 10px;
              top: 0;
          }
      }
      .result_title{
          font-size: 36px;
          color: rgb(37,105,137);
          font-weight: 400;

          @include media-breakpoint-down(sm) {
            font-size: 32px;
            color: #ffffff;
          }
      }

      .error_title{
          font-size: 36px;
          color: #a94442;
          font-weight: 400;
      }
    }
    .modal_body{
        .result_text{
            font-size: 18px;
            color: #ffffff;
            font-weight: 300;
            border-left: 2px solid $blueBg;
            padding-left: 12px;
            max-width: 390px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
              line-height: 26px;
              font-family: $additionalFont;
            }
        }

        .orange_button {
          width: 100%;
          margin: 30px 0 0 0;
          max-width: 170px;
          box-shadow: 0px 5px 7px rgba(0,0,0,0.25);
          font-weight: 300;
          @include media-breakpoint-down(sm) {
            max-width: calc(100% - 70px)!important;
          }
        }
    }
}
.modal_form{
  padding: 25px 70px;

  @include media-breakpoint-down(sm) {
      padding: 25px 0;
  }

  &_text {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
}

.modal_body{
  &_pt20{
    padding-top:20px;
  }
}


.modal_window.modal_1 {
  background-image: url(../img/assets/modals/circuit3.jpg);
  background-repeat: no-repeat;
  background-color: #122232;

  @include media-breakpoint-down(sm) {
    //height: 100vh;
    background-image: none;
  }

  .modal_body{
    .orange_button{
      margin: 20px auto 0 auto;
      padding: 14px 0;
      max-width: 250px !important;

      @include media-breakpoint-down(sm) {
        padding: 18px 0 19px;
        max-width: calc(100% - 40px)!important;
      }
    }
  }
}
.mfp-close-btn-in .mfp-close{
    display: none;
}

.modal_close {
  position: absolute;
  top: 25px;
  right: 13px;
  width: 24px;
  height: 24px;
  color: rgb(126,186,231);
  cursor: pointer;
  z-index: 20;
  transition: opacity .3s;

  &:hover{ opacity: 0.5; }

  &__icon {
    width: 100%;
    height: 100%;
    display: block;
    &:after,
    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 4px;
      top: 50%;
      background-color: #ffffff;
      transform-origin: center;
    }

    &:before {
      left: 0;
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      right: 0;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 95%;
  max-width: 590px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
  }
}

.mfp-bg, .mfp-wrap{
    z-index: 99999;
    overflow: auto;
}
.informForm .modal_window{
    padding: 57px 25px;

    @include media-breakpoint-down(sm) {
      padding: 25px;
    }

    .modal_body{
        padding-top: 20px;
    }
}

#loginForm,
#registerForm {
  max-width: 380px;
  margin: 0 auto;

  // .modal_window{
  //   overflow: visible;
  // }

  @include media-breakpoint-down(sm) {
    //height: 100vh;
    max-width: 100%;
  }

  .btn {
    display: block;
    margin-bottom: 15px;
    max-width: 100%;
    &.modal_blue_btn{
      background-image: none;
      border: 2px solid #7bb5e1 !important;
      width: 100%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 270px !important;
      }
    }
  }

  .modal_body{
     @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
     }
  }

  .modal_form {
    padding: 7px 0;

    @include media-breakpoint-down(sm) {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 50px 0 0;
    }
  }
}

.modal_form {
  &_footer {
    @include media-breakpoint-down(sm) {
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // margin: 0 auto;
      // width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      justify-content: flex-end;

      .modal_window--register & {
        position: relative;
      }
    }
  }
}

.action__controls{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 60px 0 0 0;
  .btn{
    min-width: 155px;
  }
  &_pt10{
    padding-top: 10px;
  }
}
.subscribe_title{
  font-size: 36px;
  color: #fff;
  font-family: $mainFont;
}
.subscribe_hint{
  font-size: 14px;
  color: #fff;
  font-family: $additionalFont;
  text-transform: uppercase;
}
#subscribeForm{
  .modal_window{
    padding: 37px 0;
  }
}

.subscribe__form{
  @include flexible(center, center, column);
  padding: 50px 24px 25px 30px;
  .form-group{
    // .right_blue_corner{
    //   top: -49px;
    // }
    .form-control{
      height: 45px;
      min-width: 300px;
      font-size: 22px;
    }
    .btn{
      margin: 0;
      min-width: 180px;
    }
  }
}


.mfp-container {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}
.new_modal_title{
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-family: $mainFont;
  font-weight: 400;
  text-align: left;

  @include media-breakpoint-down(sm) {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 32px;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 60px;
  }
}

.new_modal_text {
  font-size: 18px;
  color: #fff;
  text-align: left;
  padding-left: 15px;
  border-left: 2px solid #539CC2;
  margin-top: 9px;
  line-height: 1.5;
  padding-right: 20px;
  font-weight: 300;
  font-family: $additionalFont;
  max-width: 75%;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    padding-left: 25px;
    margin-bottom: 10px;
  }
}

.modal__buttons{
  margin-top: 85px;
}

.absolute__hint{
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  margin-top: 22px;
  &+.absolute__hint{
    margin-top: 50px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    left: 15px;
  }
}

.absolute__icon{
  position: absolute;
  bottom: 7px;
  right: 10px;
  font-size: 16px;
  color: rgb(127,189,237);
  &.currency{
    font-style: normal;
    bottom: 2px;
  }
  @include media-breakpoint-down(md) {
    bottom: 11px;
  }
}

.custom__icon{
  width: 30px;
  height: 30px;
  background: url(../img/icons/office/etc.png);
  &.absolute__icon{
    bottom: 1px;
    right: 0px;
  }
}


.modal-textarea{
  height: 110px;
  width: 100%;
  resize: none;
}


/* Modal with comments - white-list table */
.modal-commets-list{
  padding-bottom: 15px;
}

.modal-commets-item{
  border-bottom: 1px solid white;
  padding-bottom: 5px;

  &:not(:first-child){
    padding-top: 5px;
  }

  &__controls{
    display: flex;
    justify-content: flex-end;
  }

  &__delete{
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #ec1b1b;
    transition: opacity .3s;
    &:hover{
      opacity: 0.5;
    }
  }

  &__txt{
    color: white;
    margin-bottom: 0;
  }

}

/* Titels in modal */
.modal-title{
  display:block;
  font-weight: 300;
  font-family: "Kanit",sans-serif;
  color: white;
  line-height: 1.2;
  &_big{
    font-size: 2.4rem;
    margin: 0.67em 0;
    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
    }
  }
  &_md{
    font-size: 1.8rem;
  }
  &_up{
    text-transform: uppercase;
  }
}

/* Delete account Modal*/
.delete_account_modal{

  &__footer{
    display:flex;
    padding-top: 20px;
    .half{
      width: 50%;
    }
    .responsive_btn span+svg{
      width: 80px;
      @include media-breakpoint-up(md) {
        width: 160px;
      }
    }
  }

  .modal-title_md{
    margin-top: 55px;
  }

  .modal_window .modal_body .orange_button{
    font-weight: 400;
    margin-top: 0;
    width: 125px;

    @media (max-width: 767px){
      max-width: 80px!important;
    }

    @media (max-width: 480px){
      font-size: 30px;
      letter-spacing: 0;
      max-width: 80px!important;
    }

    .responsive_btn span{
      font-weight: 400;
    }

  }

}

