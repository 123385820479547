.custom__tabs {
  .nav-tabs {
    border: none;
    @include flexible(space-between, center, row);

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow-x: auto;
    }

    li {
      float: none;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        margin-right: 30px;
        margin-bottom: 25px;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }

      a { white-space: nowrap; }

      a.olb_tab_btn {
        display: inline-block;
        font-family: $mainFont;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        background-size: 100% 100%;
        outline: none;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 1.5px;
        border-radius: 0;
        padding: 0 12px;
        border: 2px solid #ffffff;
        transition: color .3s, background-color .3s;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: #000000;
          background-color: #ffffff;
          outline: none;

          .tab-corner--left {
            &:before {
              border-color: transparent transparent #ffffff transparent;
            }
          }

          .tab-corner--right {
            &:before {
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }

      &.active {
        a.olb_tab_btn {
          color: #000000;
          background-color: #ffffff;
          .tab-corner--left {
            &:before {
              border-color: transparent transparent #ffffff transparent;
            }
          }

          .tab-corner--right {
            &:before {
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
    }
  }

  .tab-content {
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }

    .panel-group .panel {
      border: none;
      background: none;
      margin-bottom: 25px;
    }
    .panel-heading {
      background: transparent;
      background-size: 100% 100%;
      border: none;
      padding: 0;
      border-radius: 0;
      a {
        display: block;
        font-size: 16px;
        line-height: 25px;
        color: rgb(255, 255, 255);
        font-family: $additionalFont;
        padding: 5px 25px;
        border: 2px solid #fff;
        position: relative;
        .left__button__corner {
          transform: rotate(-49deg);
          top: auto;
          bottom: -12px;
          left: -3px;
          border-right-color: #fff;
        }
        .right__button__corner {
          right: -5px;
          top: -11px;
          border-right-color: #fff;
          transform: rotate(139deg);
        }
        .fa-caret-down {
          display: none;
          position: absolute;
          top: 10px;
          right: 25px;
        }
        .fa-caret-up {
          display: block;
          position: absolute;
          top: 10px;
          right: 25px;
        }
        &.collapsed {
          border-color: #79b5e3;
          .fa-caret-down {
            display: block;
          }
          .fa-caret-up {
            display: none;
          }
          .left__button__corner,
          .right__button__corner {
            border-right-color: #79b5e3;
          }
        }
        &:hover, &:focus, &:active, &:visited {
          text-decoration: none;
        }
      }
    }
    .panel-heading + .panel-collapse > .panel-body {
      border: none;
    }
    .panel-body {
      font-size: 16px;
      line-height: 20px;
      color: rgb(255, 255, 255);
      font-family: $additionalFont;
      font-weight: 300;
    }
  }
}

.tab-corner {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #132333;
  z-index: 1;
  overflow: hidden;
  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:after {
    width: 2px;
    height: 18px;
    left: 5px;
    top: -3px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  &--left {
    top: -2px;
    left: -2px;
    &:before {
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent #132333 transparent;
      transition: border-color .3s;
    }
  }

  &--right {
    bottom: -2px;
    right: -2px;
    &:before {
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 0;
      border-color: #132333 transparent transparent transparent;
      transition: border-color .3s;
    }
  }
}