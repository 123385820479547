// settings
.absolute__user__item {
  position: absolute;
  top: 0;
  right: 0;
}

.inner__title {
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
  color: #fff;
  font-family: $mainFont;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 32px;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

.inner__rules {
  font-size: 12px;
  color: rgb(123, 182, 225);
  font-family: $additionalFont;
  font-weight: 300;
  text-transform: none;

  @include media-breakpoint-down(sm) {
    color: #cdcdcd;
    font-size: 12px;
    font-weight: 400;
  }

  .inner__title {
    font-size: 24px;
    letter-spacing: 2px;
    color: rgb(255, 255, 255);
    font-family: $mainFont;
    font-weight: 300;
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
    }
  }
}

.action__rule {
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  font-weight: 300;

  @include media-breakpoint-down(sm) {
    color: #80bce6;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
}

.format_rules {
  font-size: 12px;
  color: rgb(126, 187, 232);
  font-family: $additionalFont;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 29px;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    color: #80bce6;
    letter-spacing: .5px;
    margin-bottom: 20px;
    text-align: left;
  }
}

.settings-forms {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.settings__item {
  padding-top: 100px;
  min-width: 50%;
  .responsive_btn{
    position: relative;
    display: inline-block;
    margin: 25px auto;
  }
  .auth-form{
    margin: 0 auto !important;
  }

  @include media-breakpoint-down(md) {
    min-width: 100%;
    padding-top: 0;
  }

  .auth-form {
      margin: 0;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
        max-width: 400px;
      }

      .format_rules {
          text-align: left;

          @include media-breakpoint-down(md) {
            text-align: left;
          }
      }

      .flex-group {
          flex-direction: column;
          align-items: flex-start;

          @include media-breakpoint-down(md) {
            flex-direction: row;
          }

          @include media-breakpoint-down(sm) {
            flex-direction: column;
          }
      }
  }

  .form__wrapper {
      margin-top: 25px;
      margin-bottom: 0;

      .part__hint {
        text-align: left;

        @include media-breakpoint-down(md) {
          text-align: center;
          margin-top: 0;
        }

        @include media-breakpoint-down(sm) {
          text-align: left;
        }
      }

      .document__example {
        justify-content: flex-start;
        max-width: 100%;

        @include media-breakpoint-down(md) {
          justify-content: center;
        }
      }

      .document__example_image {
        margin-right: 30px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }
  }
}

.inform__settings{
  font-size: 14px;
  font-family: $additionalFont;
  color: #79b5e3;
  text-transform: uppercase;
  text-align: center;
  span{
    font-size: 18px;
    color: #fff;
    display: block;
  }
}

.state__info{
  font-size: 14px;
  font-family: $additionalFont;
  font-weight: 300;
  color: #79b5e3;
  margin-top: 10px; 
}


.account-setting__footer{
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom:30px;
  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom:0;
  }
}