.exchange {
  &__container {
    width: 100%;
    @include flexible(center, center, row);
    margin: 23px auto 0;

    @media  (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 auto;
      width: 100%;
    }
  }

  &__item {
    margin: 0 50px;
    white-space: nowrap;

    @media  (max-width: 500px) {    
        margin: 0px;
      & + .exchange__item {
        margin-top: 5px;
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 25px;
    color: rgb(128, 189, 234);
    font-family: $additionalFont;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 3px;

    @media  (max-width: 500px) {    
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      letter-spacing: 1px;
    }
  }

  &__count,
  &__currency {
    font-size: 36px;
    line-height: 25px;
    font-family: $mainFont;

    @media  (max-width: 500px) {
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__count {
    letter-spacing: 4px;
    color: rgb(255, 255, 255);
    font-weight: 300;

    @media  (max-width: 500px) {    
      letter-spacing: normal;
      font-weight: 600;
      text-align: left;
    }
  }

  &__currency {
    color: rgb(128, 189, 234);
    text-transform: uppercase;
  }
}
