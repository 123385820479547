.purchase__choosen {
  @include flexible(space-between, stretch, row);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.purchase__choosen__item {
  @include flexible(center, center, row);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &.divider {
    align-items: flex-start;
    padding-top: 50px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      margin-bottom: 20px;

      img {
        max-width: 70px;
        flex-basis: 70px;
        margin-right: 12px;
      }
    }
  }

  & + .purchase__choosen__item {
    @include media-breakpoint-down(sm) {
      margin-top: 35px;
    }
  }
}

.purchase__choosen__item__inner {
  height: 100%;
  @include flexible(space-between, center, column);
  font-size: 18px;
  color: rgb(62, 62, 62);
  min-width: 240px;

  @include media-breakpoint-down(sm) {
    min-width: 100%;
  }
}

.purchase__choosen_name {
  margin-top: 5px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    font-weight: 400;
    max-width: 150px;
    margin: 0;
    text-align: left;
  }
}

.purchase__choosen_divider {
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(128, 189, 234);
    font-family: $additionalFont;
}

.success-wrapper {
  padding-top: 100px;

  &__text {
    @include media-breakpoint-down(sm) {
      text-align: center!important;
      font-size: 16px!important;
      padding-left: 0!important;
      border-left: none!important;
    }
  }
}