.radio__trigger{
  @include flexible(space-between, center, row);
  max-width: 335px;
  margin: 0 auto;

  input[type="radio"]{
    display: none;

    & + label {
      display: inline-block;
      width: 150px;
      height: 45px;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      font-family: $additionalFont;
      background: url(../img/buttons/radio_bg.png);
      @include flexible(center, center, row);
      color: #7ebcec;
      &.disable{
        opacity: 0.3;
        pointer-events: none;
      }
      &:hover{
        color: #fff;
        background: url(../img/buttons/radio_bg_active.png);
      }
    }

    &:checked + label {
      color: #fff;
      background: url(../img/buttons/radio_bg_active.png);
      &.disable{
        opacity: 1;
        pointer-events: none;
      }
    }
  }
}
