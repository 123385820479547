.vision {
  &__big_image {
    position: relative;
    margin: 0 auto;
    padding: 15px;
    border: 2px solid $mainColor;
    &:before,
    &:after {
      position: absolute;
      content: "";
    }

    &:before {
      left: -3px;
      bottom: -3px;
      width: 42px;
      height: 225px;
      border-left: 4px solid #85c4ec;
      border-bottom: 4px solid #85c4ec;
    }

    &:after {
      width: 290px;
      height: 195px;
      right: -3px;
      top: -3px;
      border-top: 4px solid #85c4ec;
      border-right: 4px solid #85c4ec;
    }

    @include media-breakpoint-down(sm) {
      padding: 10px;
      &:after,
      &:before {
        display: none;
      }
    }

    .big__vision_text {
      font-size: 18px;
      line-height: 30px;
      color: rgb(255,255,255);
      font-family: $additionalFont;
      font-weight: 300;
      padding: 15px 40px 15px 15px;

      @include media-breakpoint-down(sm) {
        padding: 15px 15px 15px 0;
      }
    }
  }
}

.vision_wrapper {
  @include flexible(space-around, flex-start, row);
  position: relative;
  z-index: 9;
  margin-bottom: 25px;

  @include media-breakpoint-down(sm) {
    @include flexible(space-between, stretch, column);
  }

  // .slick-track {
  //   @include media-breakpoint-down(sm) {
  //     margin-left: -20px;
  //   }
  // }


  .vision_item-wrapper{
    &:not(:last-child){

      @media screen and (min-width: 767px) {
          margin-right: 10px;
      }

      @media screen and (min-width: 992px) {
          margin-right: 0;
      }

    }
  }

  .vision_item {
    //width: 33%;
    max-width: 362px;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #3D5F7B;
    position: relative;
    margin: 0 auto;
   
    @media screen and (min-width: 767px) {
      margin: 0;
    }

    .scroll__btn{
      display: block;
      margin: 0 auto;
      span{ display: inline; }
    }

    // @include media-breakpoint-down(md) {
    //   max-width: 230px;
    // }

    // @include media-breakpoint-down(sm) {
    //   width: 100%;
    //   max-width: 100%;
    //   min-height: 0;
    //   margin-right: 10px;
    // }



    .vision_item_image {
      width: 100%;
      max-height: 205px;
      object-fit: cover;
      object-position: center;

      @media screen and (max-width: 767px) {
        max-height: none;
        height: auto;
      }
    }

    .vision_text {
      margin-top: 0;
      min-height: 121px;
      font-size: 18px;
      color: #fff;
      font-family: $additionalFont;
      padding: 0 15px 0 0;
      font-weight: 300;
      span {
        font-weight: 400;
      }

      @include media-breakpoint-down(md) {
        padding: 0;
        margin-bottom: 25px;
      }
    }

    & + .vision_item {
      // @include media-breakpoint-up(sm) {
      //   margin-left: 10px;
      // }
      // @include media-breakpoint-up(lg) {
      //   margin-left: 20px;
      // }
    }
  }
}

#vision__border{
  position: absolute;
  top: 0;
  left: 0;
}

.moved_text {
  display: block;
  max-width: 410px;
  width: 100%;
  margin: 25px auto 30px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    width: 80%;
    margin: 10px auto 0;
  }
}

.vision__title{
  font-size: 24px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  margin-bottom: 0;
  margin-top: 15px;
}
