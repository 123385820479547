.partners__wrapper{
  @include flexible(flex-start, flex-start, row);

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .partners__item {
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      & > img {
        max-width: 180px;
        margin-bottom: 15px;
      }
    }

    &--full {
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .partners__title {
    font-size: 36px;
    color: rgb(255,255,255);
    font-family: $mainFont;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .partners__descr {
    border-left: 2px solid #7ebcec;
    font-family: $additionalFont;
    font-weight: 300;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 0 15px 0 15px;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }
  }

  .partners__link{
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-decoration: underline;
    font-family: $additionalFont;
    .fa{
      color: rgb(115,176,225);
      margin-right: 5px;
    }
  }
}
