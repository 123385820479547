.deepToken {
  &__wrapper {
    @include flexible(space-between, flex-start, row);

    .timer {
      padding-top: 22px;
    }

    @media screen and (max-width: 425px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__icon {
    padding: 49px 0 0 0;
    position: relative;
    left: -17px;

    @media screen and (max-width: 991px) {
      left: 0;
      min-width: 200px;
      flex-basis: 200px;
    }

    @media screen and (max-width: 425px) {
      left: 0;
      min-width: 100px;
      flex-basis: 100px;
      padding: 30px 0 0 0;
    }

    &.timer__icon {
      max-width: 685px;
      left: 0;
      right: 0;
      margin: 0 auto;
      
      @include media-breakpoint-down(sm) {
        text-align: center;
      }

      &+.exchange__container{
        max-width: 685px;
      }

      &.blocked {
        #timer__big {
          display: none;
        }

        #timer__big_blocked {
          display: block;
        }
      }
    }
  }

  &__item {
    padding: 50px 0 0 0;
    position: relative;
    z-index: 9;
    @media screen and (max-width: 992px) {
      padding: 0;
    }

    &.live__item {
      padding: 0;
      width: 100%;
    }
  }
}

.deeptoken {
  &__section {
    position: relative;
    background: #07111D;

    @include media-breakpoint-down(md) {
      background-color: #051323;
    }

    @include media-breakpoint-down(sm) {
      border-top: 2px solid#6ca4d1;          
      padding-top: 30px;
      @include section-divider;
    }

    .container {
      position: relative;
      z-index: 9;
    }
  }

  &__wrapper {
    @include media-breakpoint-down(md) {
      margin-top: 115px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  &__icons {
    @include flexible(space-beetwen, flex-start, row);
    margin-bottom: 28px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      // flex-direction: column;
    }
  }

  &__icon_item {
    object{
      // width: 350px;
    display: block;
    height: 360px;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
      @include media-breakpoint-down(md) {
        width: 210px;
      }
    }
  }

  &__descr {
    border-left: 2px solid #7ebcec;
    font-family: $additionalFont;
    font-weight: 300;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px 0 15px;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 30px;
      font-weight: 400;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 10px 0 25px;
    }
  }
}
