.header {
  background: transparent;
  z-index: 9999;

  .menu-open & {
    background-color: #051323!important;
    background-image: none!important;
    z-index: 9999;
  }
}

.fixed_header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  transition: background-color .3s;

  & + .section {
    padding-top: 52px;
    min-height: 100vh;

    @include media-breakpoint-up(md) {
      padding-top: 103px;
    }

    @include media-breakpoint-down(sm) {
      min-height: auto;
    }
  }

  &.sticky {
    background-color: rgba(7,17,29,.95);
    background-image: url(../img/bg/nav_bg.png);
    background-position: top center;
  }

  @include media-breakpoint-down(lg) {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  }
}


.main_logo {
  max-width: 122px;
  margin-top: 10px;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    margin-top: 18px;
    margin-bottom: 18px;
    max-width: 210px;// 220
  }

  &:hover { opacity: 0.8; }
}
