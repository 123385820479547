.row {
  &--flex {
    display: flex;

    @supports(display: flex) {
      &:before,
      &:after {
        display: none!important;
      }
    }
  }

  &--flex-wrap {
    flex-wrap: wrap;
  }

  &--flex-middle {
    align-items: center;
  }
  &--flex-bottom {
    align-items: flex-end;
  }
}