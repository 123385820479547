$corner-size-lg: 56px;
$corner-size-lg-mobile: 36px;

$corner-size-sm: 50px;
$corner-size-sm-mobile: 30px;

$corner-border-lg: 2px;
$corner-border-sm: 1px;

$corner-bg-lg: #07111D;
$corner-bg-sm: #122232;

.right-corner {
  position: absolute;
  right: 0;
  bottom: 0;
  &:after,
  &:before {
    position: absolute;
    content: "";
  }

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
  }

  &:before {
    background-color: $mainColor;
    z-index: 5;
    transform: rotate(45deg);
    transform-origin: right top;
  }


  &--lg {
    width: $corner-size-lg;
    height: $corner-size-lg;
    right: -$corner-border-lg;
    bottom: -$corner-border-lg;
    &:after {
      background-color: $corner-bg-lg;
    }

    &:before {
      width: $corner-border-lg;
      height: 80px;
      left: 54px;
      top: 0;
    }

    @include media-breakpoint-down(sm) {
      width: $corner-size-lg-mobile;
      height: $corner-size-lg-mobile;
      &:before {
        height: 51px;
        left: 34px;
      }
    }
  }

  &--sm {
    width: $corner-size-sm;
    height: $corner-size-sm;
    right: -$corner-border-sm;
    bottom: -$corner-border-sm;
    &:after { background-color: $corner-bg-sm; }

    &:before {
      width: $corner-border-sm;
      height: 71px;
      left: 49px;
      top: -1px;
    }

    @include media-breakpoint-down(md) {
      width: $corner-size-sm-mobile;
      height: $corner-size-sm-mobile;
      &:before {
        height: 43px;
        left: 29px;
      }
    }
  }
}
