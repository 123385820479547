// Mixins
@mixin flexible($horizontal, $vertical, $direction){
  display: flex;
  flex-direction: $direction;
  justify-content: $horizontal;
  align-items: $vertical;
}
@mixin verticalGrad($top, $bottom){
  background: $top;
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
  background: -webkit-linear-gradient(top, $top 0%,$bottom 100%);
  background: linear-gradient(to bottom, $top 0%,$bottom 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$top', endColorstr='$bottom',GradientType=0 );
}
@mixin horizontalGrad($left, $right){
  background: $left;
  background: -moz-linear-gradient(left, $left 0%, $right 100%);
  background: -webkit-linear-gradient(left, $left 0%, $right 100%);
  background: linear-gradient(to right, $left 0%, $right 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$left', endColorstr='$right',GradientType=1 );
}

@mixin section-divider() {
  &:before,
  &:after {
    position: absolute;
    z-index: 999;
    content: "";
  }

  &:before {
    left: 0;
    top: 0;
    width: 80px;
    height: 8px;
    background-color: #6ca4d1;
  }

  &:after {
    right: 0;
    top: -8px;
    width: 60px;
    height: 8px;
    background-color: #6ca4d1;
  }
}