.form-control{
  &.has-error:valid{
    border-color: red;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    &+.blue__corners{
      .left_blue_corner,
      .right_blue_corner{
        border-right-color: red;
      }
    }
  }
}
.hidden_input{
  display: none;
}
.hidden_input:checked + .custom_checkbox:before{
  background: $mainColor;
  outline: 1px solid  #bdbdbd;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
}
.custom_checkbox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  &:before{
    content: " ";
    display: inline-block;
    width: 15px;
    height: 15px;
    @include verticalGrad(#fff, #F6F6F6);
    border: 1px solid #bdbdbd;
    margin-right: 5px;
  }
}

.form__link{
  margin: 0 5px;
  text-decoration: underline;
  color: #296e8e;
  font-weight: 500;
}

.auth-form__counter {
  margin-bottom: 10px;
  font-size: 12px;
  color: #7ebbe8;
  font-family: "Kanit",sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;

  strong { color: #ffffff; font-size: 14px; }
}

.auth-form,
.custom-form{
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    max-width: 450px;
  }

  &.no-width-limit{
    max-width: 100%;

    .form-group{
      margin-bottom: 15px;

      @include media-breakpoint-down(md)  {
        width: 100%;
        padding: 0 10px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
    .flex-buttons{
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .form-group{
    margin-bottom: 30px;

    label, .image-form__text{
      font-weight: 500
    }
  }

  .btn {
    width: 80%;
    max-width: 300px;
    margin-top: 12px;
  }

  .hint {
    position: relative;
    display: inline-block;
  }

  .label__hint,
  .hint{
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(123,182,225);
    font-family: $additionalFont;
    font-weight: 300;
    margin: 15px 0 0 10px;
  }

  .image-form__label {
    @include flexible(space-around, center, row);
    width: 100%;
    max-width: 359px;
    min-width: 359px;
    min-height: 161px;
    background: url(../img/label/bg.png);
    background-size: 100% 100%;
    padding: 13px;
    position: relative;
    margin: 0 auto;
    &.big{
      max-width: 550px;
    }
    @media screen and (max-width: 768px){
      min-width: 100%;
    }

    &.many_images{
      .image__wrapper{
        &:nth-child(3){
          .change__image_wrap{
            left: auto;
            right: 0;
          }
        }
      }
      .change__image_wrap{
        width: 50%;
      }
    }
    &.uploaded {
      // background-color: #000;

      .images-list .image__wrapper{
        position: static;
      }
      &:hover{
        .uploaded__fill, .change__image_wrap{
          opacity: 1;
        }
      }
      .uploaded__fill{
        @include flexible(center, center, column);;
      }
    }

    .inner_label {
      @include flexible(space-between, center, column);
      width: 100%;
      height: 100%;
      min-height: 135px;
      border: 1px dashed #fff;
      cursor: pointer;
      color: rgb(167,169,172);
      text-transform: uppercase;
      font-size: 12px;
      min-width: 50%;
      padding: 5px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        border-color: #7ab4e7;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
  .image-form__label + .image-control{
    display: none;
  }

  .flex-buttons {
    @include flexible(center, flex-start, row);

    .form__wrapper--center  & {
      @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }

    .btn {
      width: auto;
      min-width: 155px;
    }

    @include media-breakpoint-down(sm) {
      .orange_button {
        width: 100%!important;
        max-width: 35%!important;
        min-width: auto!important;
        margin: 0 0 0 22px;
      }

      .blue_button {
        height: 60px;
        padding: 5px 0!important;
        margin: 0!important;
        width: 240px;
      }

      .btn {
        // width: auto;
        min-width: 45%;
      }
    }
  }
}

.settings__container{
  .custom-form{
    max-width: 810px;
    padding: 184px 0 50px 0;
    .form-group{
      margin-bottom: 35px;
    }
    .btn{
      max-width: 165px;
      margin-top: 20px;
    }
  }
}

label{
  font-size: 14px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  font-weight: 400;
  margin-bottom: 4px;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.form-control {
  height: 30px;
  border: 2px solid #7bb5e1;
  background: #132232;
  font-size: 14px;
  line-height: 25px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  border-radius: 0;
  &:focus{
    background: #2A3846;
    box-shadow: none;
  }

  @include media-breakpoint-down(md) {
    height: 40px;
    background: #051323;
  }

  .modal_body & {
    @include media-breakpoint-down(md) {
      background: #122232;
    }
  }
}

.form-group{
  position: relative;
}

.custom-input {
  position: relative;
}

.left_blue_corner {
  display: inline-block;
  position: absolute;
  bottom: -4px;
  left: -1px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #7bb5e1;
  border-bottom: 7px solid transparent;
  transform: rotate(-45deg);

  .steps-list__item &,
  .scroll__btn & {
    bottom: -5px;
  }

  .corner__inner {
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -7px;
      left: -3px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-right: 8px solid #122232;
      border-bottom: 8px solid transparent;
    }

    @include media-breakpoint-down(sm) {
      &:after {
        border-right: 8px solid #051323;
      }
    }

    .modal_body & {
      @include media-breakpoint-down(sm) {
        &:after {
          border-right: 8px solid #122232;
        }
      }
    }
  }
}

.right_blue_corner {
  display: inline-block;
  position: absolute;
  top: -4px;
  right: -1px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #7bb5e1;
  border-bottom: 7px solid transparent;
  transform: rotate(135deg);

  .steps-list__item &,
  .scroll__btn & {
    top: -5px;
  }

  .corner__inner {
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -7px;
      left: -3px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-right: 8px solid #122232;
      border-bottom: 8px solid transparent;
    }

    @include media-breakpoint-down(sm) {
      &:after {
        border-right: 8px solid #051323;
      }
    }

    .modal_body & {
      @include media-breakpoint-down(sm) {
        &:after {
          border-right: 8px solid #122232;
        }
      }
    }
  }
}

.corner__inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.change__pass_form{
  margin: 0 auto !important;
}
.custom__checkbox{
  display: none;
  &+label{
    width: 15px;
    height: 15px;
    border: 2px solid #79B5E3;
    margin-bottom: 0;
    margin-right: 10px;
    position: relative;
  }
  &:checked{
    &+label{
      &:after{
        content: "\f00c";
        font-family: FontAwesome;
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 1px;
        top: -3px;
        font-size: 10px;
        color: #79B5E3;
      }
    }
  }
}
.terms__text{
  font-size: 18px;
  color: rgb(255,255,255);
  font-family: "Kanit";
  a{
    color: #79B5E3;
    text-decoration: underline;
  }
}
.diff_width{
  @include media-breakpoint-down(sm){
      width: 190px !important;
  }
}
.form-control.filter__field{
  width: 150px;
  height: 30px;
  line-height: 1;
  padding: 2px 5px;
  font-weight: 300;
  // position: absolute;
  // top: 0;
  // right: 0;
  @include media-breakpoint-down(sm){
    position: static;
    width: 100%;
    border-color: #fff;
  }

  & + .form-control.filter__field{
    @include media-breakpoint-up(sm){
      margin-right: 5px;
    }
  }
}
