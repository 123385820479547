.user {
  &__menu-wrapper {
    @include media-breakpoint-down(md) {
      position: absolute;
    }
  }
}

.user__control__link {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
  font-family: $additionalFont;
  &[disabled] {
    color: rgb(110, 110, 110);
    text-decoration: none;
    pointer-events: none
  }

  @include media-breakpoint-down(md) {
    margin-left: 0;
    color: #ffffff;
    font-family: Kanit;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      text-decoration: none;
      color: #ffffff;
    }

    & + .user__control__link {
      margin-top: 15px;
    }
  }
}

.user__name_wrap {
  color: #fff;
  cursor: pointer;
  @include flexible(flex-end, center, row);
  .fa{
    font-size: 16px;
  }
  &:hover{
    .user_name,
    .fa {
      color: #6DC7FF;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 15px;
    align-items: flex-start;
    justify-content: flex-start;

    .fa { font-size: 20px; }
  }
}

.user__name {
  font-size: 24px;
  line-height: 25px;
  color: rgb(237, 225, 177);
  word-wrap: break-word;
  width: 100%;

  &__title {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 5px;
      position: relative;

      .fa {
        position: absolute;
        left: -25px;
        top: 5px;
      }
    }
  }

  &_wrap {
    position: relative;
    color: #fff;
    cursor: pointer;
    justify-content: flex-end;
    display: flex;
    flex-direction: row-reverse;
  
    @include media-breakpoint-up(lg) {
      @include flexible(flex-end, center, row);
    }
  
    @media screen and (max-width: 991px) {
      margin-left: 10px;
    }
  
    .fa {
      font-size: 16px;
  
      @media screen and (max-width: 991px) {
        position: absolute;
        top: 50%;
        left: -10px;
        font-size: 22px;
        color: $mainColor;
        transform: translateY(-50%);
      }
  
      @media screen and (max-width: 768px) {
        left: -24px;
      }
    }
  }
}

.user__name__wrapper {
  font-size: 24px;
  color: #fff;
  font-family: $additionalFont;

  @include media-breakpoint-down(md) {
    color: #8497a4;
    font-size: 20px;
    font-weight: 500;
    padding-left: 30px;
    margin-bottom: 15px;
    max-width: 210px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.user__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 76px;

  @include media-breakpoint-down(md) {
    position: absolute;
    right: -100%;
    width: 320px;
    padding: 0 20px;
    background-color: #051323;
    top: 0;
    min-height: 65px;
    height: 100vh;
    max-height: 100vh;
    font-family: $mainFont;
    text-align: left;
    transition: right .4s;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 9999;

    .fa {
      color: #80bce6;
      margin-right: 5px;
    }

    .menu-open  & {
      right: -115px;
      box-shadow: none;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    .menu-open  & {
      right: 0;
    }
  }

  @media (max-width: 480px) {
    width: 100%!important;

    // .menu-open  & {
    //   right: 0;
    // }
  }

  &__back {
    display: none;
    background: transparent;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      color: #6ca4d1;
      font-family: Kanit;
      font-size: 20px;
      font-weight: 500;
      line-height: 44px;
      margin-bottom: 35px;

      .fa { 
        font-size: 44px;
        margin-right: 15px;
      }

      &:hover {
        color: #6ca4d1;
        text-decoration: none;
      }
    }
  }
}

.user__controls {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}