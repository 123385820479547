.slider {
  padding: 20px 50px 78px 50px;
  margin: 0 -15px;

  @include media-breakpoint-down(md) {
    padding: 20px 0 78px 0;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0 45px 0;    
  }

  .slick-track {
    @include media-breakpoint-down(sm) {
      margin-left: -12px;
    }
  }
}

.slick-arrow {
  color: transparent;
  box-shadow: none;
  overflow: hidden;
  position: absolute;
  top: 50%;
  border: none;
  z-index: 9;
  opacity: 0.5;
  transition: all linear 0.5s;
  background: none;
  transform: translateY(-50%);

  &:hover { opacity: 1; }

  .slider &{
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      display: none;
    }
  }
  &:active,
  &:focus {
    outline: none;
  }
  &.slick-prev {
    left: 15px;
    //top: calc(50% - 25px);
    font: normal normal normal 14px/1 FontAwesome;
    color: transparent;
    &:before{
      content: "\f104";
      color: #fff;
      font-size: 55px;
    }
  }
  &.slick-next {
    right: -15px;
    color: #fff;
    //top: 50%;
    font: normal normal normal 14px/1 FontAwesome;
    color: transparent;
    &:before{
      content: "\f105";
      color: #fff;
      font-size: 55px;
    }
  }
}



.slick-slide:active,
.slick-slide:focus {
  outline: none;
}

.slick-slide {
  img {
    width: 100%;
    height: 144px;
    object-fit: cover;
  }
}

.slider_item {
  @include verticalGrad(#102d40, #142130);
  color: #fff;
  margin: 15px;
  min-height: 672px;
  // @include flexible(flex-start, center, column);
  position: relative;
  padding-bottom: 65px;
  transition: all linear 0.3s;

  @include media-breakpoint-up(sm) {
    min-height: 640px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 586px;
    max-width: 265px;
  }

  @media screen and (max-width: 767px) {
    max-width: 95%;
    margin: 0;
  }


  &:hover {
    box-shadow: 3px 3px 5px 1px rgba(23, 22, 22, 0.7);
  }

  .slide_year {
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 25px;
    color: rgb(255, 255, 255);
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
    font-family: $mainFont;

    @include media-breakpoint-down(sm) {
      text-align: left!important;
      font-weight: 600;
    }
  }

  .slide_title {
    font-size: 15px;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-family: $additionalFont;
    padding: 0 10px;
    min-height: 60px;

    @include media-breakpoint-down(sm) {
      padding: 0;
      text-align: left!important;
      font-size: 20px;
    }
  }

  .slide_text {
    //width: 232px;
    font-size: 16px;
    line-height: 18px;
    color: rgb(255, 255, 255);
    font-family: "Kanit";
    text-align: $additionalFont;
    padding: 0 10px;
    a {
      text-decoration: underline;
      color: #fff;
    }

    @include media-breakpoint-down(sm) {
      text-align: left!important;
      padding: 0;
      font-size: 16px;
      line-height: 26px;
      color: #cdcdcd;
    }
  }

  .more_button,
  .more_button:active,
  .more_button:focus {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 75px);
    width: 150px;
    height: 45px;
    color: #7EBCEC;
    transition: all linear 0.3s;
    outline: none;
    border: 2px solid #7EBCEC;

    .right__button__corner,
    .left__button__corner {
      border-right: 15px solid #7EBCEC;
    }

    .left__button__corner {
      position: absolute !important;
      left: -4px;
      top: auto;
      bottom: -11px;
      transform: rotate(315deg);
    }
    .right__button__corner {
      position: absolute !important;
      right: -4px;
      bottom: auto;
      top: -11px;
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: rotate(135deg);
    }

    @include media-breakpoint-down(sm) {
      width: 98%;
      left: 3px;
      font-size: 24px;
      font-weight: 700;
      bottom: 0;
    }
  }

  &_content {
    @include media-breakpoint-down(sm) {
      padding: 0 12px;
    }
  }
}