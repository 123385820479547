.status {
  &__wrapper {
    @include flexible(flex-start, stretch, row);
    padding: 10px 0;
    min-height: 70px;

    @include media-breakpoint-down(md) {
      justify-content: space-between;
      white-space: nowrap;
      overflow-x: auto;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    min-width: 195px;
    border-left: 2px solid #559dbf;
    padding: 0 10px;
    position: relative;

    .fa-info-circle {
      font-size: 27px;
      color: rgb(193, 193, 193);
      position: relative;
      top: -10px;
      left: 10px;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
        top: 0;
        font-size: 22px;
        color: #6f8193;
      }
    }

    @include media-breakpoint-down(lg) {
      min-width: 150px;
    }

    @include media-breakpoint-down(sm) {
      min-width: auto;
      justify-content: center;
      border-left: none;
    }
  }

  &__info {
    position: absolute;
    top: 130%;
    left: 0;
    min-width: 305px;
    z-index: 99;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.13);
    background-color: #ffffff;
    border: 2px solid #5b9fc1;
    padding: 15px;

    @include media-breakpoint-down(sm) {
      min-width: 200px;
      position: fixed;
      top: 120px;
      left: 115px;
    }

    &_list {
      list-style: none;
      margin: 0;
      padding: 0;

      .status__info_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: rgb(60, 60, 60);
        text-transform: uppercase;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
        .success {
          color: rgb(100, 163, 70);
        }
        .error {
          color: rgb(200, 72, 65);
        }
        .pending {
          color: rgb(110, 110, 110);
        }
      }
    }
  }
}

.status-item {
  &__name {
    font-size: 14px;
    line-height: 25px;
    color: rgb(111, 165, 206);
    font-family: $additionalFont;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 18px;
      margin-bottom: 3px;
    }
  }

  &__value {
    font-size: 36px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
    }

    &.approved{
      color: #fff;
    }
    &.bronze{
      color: #DDA365;
    }
    &.silver{
      color: #D8D8D8;
    }
    &.gold{
      color: #F7CD80;
    }
    &.platinum{
      color: #f2f2f2;
    }
  }
}
