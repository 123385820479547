.ended {
  &__wrapper {
    @include flexible(flex-start, flex-start, row);
    margin-top: 60px;

    @include media-breakpoint-down(md) {
      // max-width: 440px;
      // margin: 0 auto;
      // margin-top: 50%;
      // transform: translateY(-50%);
      max-width: 505px;
      margin-top: 0;
      padding-top: 120px;
      padding-bottom: 70px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 45px;
      padding-bottom: 30px;
    }
  }

  &__item {
    justify-content: center !important;

    &.account__ended__item {
      .ended__wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;

        .ended__data {
          margin-top: 20px;
          margin-bottom: 25px;
        }

        .ended__title {
          font-size: 52px;
          letter-spacing: 3px;
          color: rgb(255, 255, 255);
          font-family: $mainFont;
          text-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
        }
      }
    }

    &__value {
      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
  }

  &__title {
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
    font-size: 48px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 31px;
    margin-top: -16px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      // font-size: 52px;
      // line-height: 1.2;
    }

    @include media-breakpoint-down(sm) {
      font-size: 36px;
    }
  }

  &__info {
    max-width: 615px;
    font-family: "Kanit",sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: white;
  }

  &__list {
    margin: 0 0 0 47px;
    padding: 0 0 0 10px;
    border-left: 3px solid #6DA5CF;
    list-style: none;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

    &_item {
      font-size: 48px;
      line-height: 25px;
      color: rgb(255, 255, 255);
      font-family: $mainFont;
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        font-size: 40px;
      }

      .ended__item__name {
        font-size: 18px;
        line-height: 25px;
        color: rgb(127, 189, 237);
        font-family: $additionalFont;
        margin-bottom: 15px;
        display: block;

        @include media-breakpoint-down(md) {
          text-transform: uppercase;
        }

        @include media-breakpoint-down(sm) {
          font-size: 16px;
          margin-bottom: 0;
        }
      }

      .currency {
        color: rgb(110, 165, 207);
        text-transform: uppercase;

        //@include media-breakpoint-down(sm) {
        //  display: block;
        //  margin-top: 10px;
        //  margin-bottom: 25px;
        //}
      }
    }
  }
}

.end__wrapper_inner{
  background: #11293a;
  padding: 30px;
  width: 100%;  
  margin: 70px 0 100px 0;
}
.end__wrapper_title{
  font-size: 24px;
  color: #fff;
  font-family: $additionalFont;
}
.end__wrapper_text{
  color: #a8d1ef;
  font-size: 18px;
  font-family: $additionalFont;
  padding: 0 15px;
  p{
    margin-bottom: 30px;
  }
}
.end__wrap__result{
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: $additionalFont;
}
