.timer {
  @include flexible(flex-start, center, row);
  padding: 72px 10px 0 29px;

  @include media-breakpoint-down(sm) {
    padding: 45px 12px 16px!important;
    width: 100%;
    max-width: 500px;
    margin: -13px auto 25px;
    border: 2px solid #406485;
    position: relative;
  }

  @media (max-width: 425px) {
    padding: 45px 5px 16px!important;    
  }

  &__wrapper {
    width: 685px;
    margin: 50px auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 30px 0;
      text-align: center;
    }
  }

  &__item {
    min-height: 197px;
    position: relative;

    @include media-breakpoint-down(sm) {
      min-height: auto;
    }

    &_name {
      position: absolute;
      top: 18px;
      left: 252px;
      text-transform: uppercase;
      font-size: 48px;
      letter-spacing: 2px;
      line-height: 25px;
      color: rgb(255, 255, 255);
      font-family: $mainFont;
      font-weight: 300;

      @include media-breakpoint-down(sm) {
        position: relative;
        top: auto;
        left: auto;
        z-index: 10;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        padding: 10px 10px 7px;
        display: inline-block;
        border: 2px solid #406485;
        background-color: #051323;
        margin-bottom: -15px;
      }
    }
  }

  &__name {
    font-size: 36px;
    line-height: 25px;
    color: rgb(241, 227, 177);
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    top: -5px;
    left: 223px
  }

  &__text {
    font-size: 18px;
    line-height: 25px;
    font-family: $additionalFont;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    width: 90px;

    @include media-breakpoint-down(sm) {
      width: auto;
      letter-spacing: 1px;
      font-size: 12px;
      margin-top: 8px;
      font-weight: 400;
    }
  }

  &__digit {
    font-size: 72px;
    line-height: 25px;
    color: rgb(255, 255, 255);
    font-family: $mainFont;
    text-align: center;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.13);
    @include flexible(center, center, row);
    width: 90px;
    height: 85px;
    margin-right: 89px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      font-size: 32px;
      letter-spacing: 3px;
      width: auto;
      height: auto;
      font-weight: 600;
      width: 50px;
      height: 47px;
      padding: 12px 6px;
      border: 1px solid #828991;
      background-color: #051323;
      position: relative;
      line-height: 1;
      &:after {
        position: absolute;
        content: "";
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 1px solid #051323;
        border-radius: 13px;
      }
    }
  }

  &__points {
    @include flexible(center, center, column);
    margin-top: -18px;
    .timer__point {
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #fff;
      margin-bottom: 20px;
    }
  }

  &__hint {
    font-size: 16px;
    color: rgb(110, 110, 110);
    text-transform: uppercase;
    padding: 15px 0;
    font-weight: 500;

    .timer_count {
      display: inline-block;
      width: 23px;
    }
  }

  &__data {
    position: relative;
    @include media-breakpoint-down(sm) {
      @include flexible(center, center, column);
      width: 25%;
      
      & + .timer__data {
        &:after {
          position: absolute;
          content: ":";
          color: #828991;
          font-size: 20px;
          left: -4px;
          font-size: 52px;
          top: -9px;
        }
      }
    }
  }

  &__corner {
    @include media-breakpoint-down(sm) {
      position: absolute;
      width: 17px;
      height: 17px;
      overflow: hidden;
      background-color: #051323;
      &:after {
        position: absolute;
        content: "";
        left: 9px;
        top: -6px;
        width: 2px;
        height: 27px;
        background-color: #406485;
        transform: rotate(45deg);
      }

      &--left {
        top: -2px;
        left: -2px;
      }

      &--right {
        right: -2px;
        bottom: -2px;
      }
    }
  }
}