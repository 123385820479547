.team_wrapper {
  // @include flexible(space-between, flex-start, row);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 45px 50px 0 58px;
  margin: 0 -22px -3px -22px;
  position: relative;
  z-index: 9;

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 20px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  @media screen and (min-width: 992px) and (max-width: 1270px){ // fast fix
     padding-right: 100px;
  }

  & > .team_wrapper{
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: 1270px) and (min-width: 992px){
      padding-right: 0;
    }
  }


  &--mobile-center {
    @include media-breakpoint-down(md) {
      justify-content: space-around;
    }
  }

  &.no_border{
    align-items: center;
    .team__inner{
      border: none;
    }
  }

  &.center__wrapper{
    width: 100%;
    justify-content: center;
    padding-top: 87px;
    margin-bottom: 50px;
    width: 100%;

    @include media-breakpoint-down(md) {
      justify-content: space-between;
      padding-top: 15px;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }

    .team__inner {
      margin: 0 50px;

      @include media-breakpoint-down(md) {
        // width: 100%;
        margin: 75px 10px;
      }

      @include media-breakpoint-down(sm) {
        margin: 60px 0 40px;
      }
    }
  }
}

.team__title {
  font-size: 18px;
  line-height: 25px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  text-align: center;
  font-weight: 300;
  margin: 75px 0 50px 0;

  @include media-breakpoint-down(md) {
    margin: 0 0 10px 0;
  }
}

.jspPane {
  @media screen and (max-width: 1024px){
    position: relative!important;
    left: 0!important;
    top: 0!important;
    width: 100%!important;
    margin: 0!important;
    padding: 0!important;
  }
}

.team__inner {
  width: 28%;
  text-align: center;
  margin-bottom: 75px;
  margin-top: 50px;
  position: relative;
  border: 2px solid #80bce6;

  @media screen and (max-width: 992px) {
    width: 45%;
    margin: 75px 0;
    border: 0;
    border: 1px solid #80bce6;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 0;
    margin: 65px 0 40px;
    border: 1px solid #80bce6;
  }

  .team_decor {
    width: 12px;
    height: 20px;
    background: #07111D;
    position: absolute;
    bottom: -8px;
    right: -4px;
    transform: rotate(45deg);
    border-left: 2px solid #80bce6;
  }

  .no_border & {
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }
}

.team_name__position{
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
  }

.team_item {
  z-index: 9;
  background-size: 100% 100%;
  padding-bottom: 50px;

  &.advisor{
    .team_name{
      position: relative;
      margin-top: 30px;
    }
  }

  &.small_border{
    background: url(../img/svg/team/team_min.svg);
    background-size: 100% 100%;
    background-position: 0 30px;
    background-repeat: no-repeat;
    border: none;

    .team_decor {
      display: none;
    }

    .team_name {
      padding-bottom: 20px;
    }

    @media screen and (max-width: 992px) {
      background: none;
      .team_name{
        padding-bottom: 0;
      }
    }
  }

  &:after {
    content: " ";
    display: inline-block;
    width: 200px;
    height: 200px;
    background: url(../img/svg/team/m_big.svg);
    background-size: 100% 100%;
    position: absolute;
    top: -60px;
    left: -60px;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    background: none;
    border: none;
  }
}

.team_item.advisor {
  z-index: 9;
  background-size: 100% 100%;
  &:after {
    content: " ";
    display: inline-block;
    width: 210px;
    height: 200px;
    background: url(../img/svg/team/a.svg);
    background-size: 100% 100%;
    position: absolute;
    top: -60px;
    left: 37%;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    background: none;
    // border: 2px solid #7ab6e4;
  }
}

.team_text {
  @include flexible(flex-start, flex-start, row);
  font-size: 14px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  font-family: $additionalFont;
  font-weight: 300;
  min-height: 65px;
  border-top: 2px solid #80bce6;
  padding: 15px 10px;
  text-align: justify;
  position: relative;
  max-height: 140px;
  min-height: 140px;
  height: 100%;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    background: none;
    border: none;
    border-top: 1px solid #7ab6e4;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .5px;
    font-weight: 400;
  }


  &_inner{

  }

  &.show-content {
    max-height: 100%;
  }
}

// #team__wrapper{
//   .scroll__button{
//     background: url(../img/assets/mask.png);
//   }
// }


.team_photo {
  max-width: 120px;
  border-radius: 50%;
  // margin-top: -68px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}



.team_member {
  // width: 100%;
  // height: 100%;
  display: block;
  text-align: center;
  color: #fff;

    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -90px;
    max-width: none !important;
    // position: relative;

    @include media-breakpoint-down(md) {
      width: 165px;
      height: 165px;
    }

    @include media-breakpoint-down(sm) {
      width: 100px;
      height: 100px;
      margin-top: -65px;
    }



  // @include flexible(center, center, row);
  font-size: 85px;
  position: relative;
  z-index: 9;
  // padding: 6px 0 0 0;
  &:after{
    content: "\f0e1";
    display: block;
    width: 100%;//150
    height: 100%;//150
    position: absolute;
    top: 0;
    left: 0;
    background: url(../img/assets/under_avatar.png);
    background-size: cover;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 30px;
    line-height: 162px;
    color: #fff;
    opacity: 0;
    @media screen and (max-width: 992px){
      display: none;
    }
  }




  &:hover,
  &:active
  &:focus,
  &:visited{
    opacity: 1;
    text-decoration: none;
    color: #fff;
    &:after{
      opacity: 1;
    }
  }

  img {
    display:block;
    border-radius: 50%;
    width: 150px;
    // margin-top: -90px;
    max-width: none !important;
    // position: relative;

    @include media-breakpoint-down(md) {
      width: 165px;
    }

    @include media-breakpoint-down(sm) {
      width: 100px;
      // margin-top: -65px;
      border: 1px solid $mainColor;
    }
  }


  &.disable{
    &:after{
      content: " ";
      display: none;
    }
    &:hover,
    &:active
    &:focus,
    &:visited{
      opacity: 1;
      text-decoration: none;
      color: #fff;
    }
  }
}

.team_name {
  text-align: center;
  font-size: 27px;
  line-height: 25px;
  color: rgb(255,255,255);
  font-family: $additionalFont;
  font-weight: 300;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(0,0,0,0.15);
  margin-top: 23px;
  margin-bottom: 7px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 26px;
    margin-top: 12px;
    font-weight: 500;
  }

  &.long__name{
    font-size: 23px;//27
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
}

.team_banner{
  max-width: 750px;
  min-height: 415px;
  width: 90%;
  margin: 0 auto;
  @include flexible(space-around, center, row);
  font-size: 85px;
  color: #fff;
  position: relative;
  z-index: 9;
  img{
    width: 100%;
  }
}
