.calculator__buttons{
  @include flexible(center, center, row);
  min-height: 100px;
  margin-top: 10px;
  position: relative;
	z-index: 99;
	
	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}

  .calculator__item {
		margin: 0 10px;

		@include media-breakpoint-down(sm) {
			& + .calculator__item {
				margin-top: 10px;
			}
		}
		
    .responsive_btn{
      position: relative;
			display: inline-block;
			white-space: nowrap;
      &.red,
      &.yellow{
        pointer-events: none;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .calculator__hint{
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 300;
      display: block;
      text-align: center;
      color: #fff;
			font-family: $additionalFont;
			
			@include media-breakpoint-down(sm) {
				font-size: 12px;
				font-weight: 400;
			}

      .fa{
        color: #6fa5ce;
      }
    }
	}
	
  .responsive_btn .cls-1 {
	       fill: #7fbded;
	       -webkit-transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
		   transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	     }
	    // .responsive_btn .cls-2 {
	    //    fill: url(#linear-gradient);
	    //  }
	    .responsive_btn .cls-3 {
	       fill: #fff;
	       fill-opacity: 0;
	       -webkit-transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
		   transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	     }
		.responsive_btn:hover .cls-3 {
			opacity: 1;
		}
		.responsive_btn:hover .a {
			fill: #aad9f9;
		}
		.responsive_btn:active .b {
			fill: white;
		}

		.responsive_btn.red .a {
			fill: #ed7f7f;
		}
		.responsive_btn.red .b {
			fill: #973535;
		}
		.responsive_btn.red span {
			color: #bdbdbd !important;
		}

		.responsive_btn.yellow .a {
			fill: #f2d8a5;
		}
		.responsive_btn.yellow .b {
			fill: #dbc56e;
		}
		.responsive_btn.yellow span {
			color: #bdbdbd !important;
		}

		.responsive_btn.green .a {
			fill: #acd19e;
		}
		.responsive_btn.green:hover .a {
			fill: #b7dda9;
		}
		.responsive_btn.green .b {
			fill: #46b250;
		}
		.responsive_btn.green:active .b {
			fill: white;
		}

		.responsive_btn span {
			text-align: center;
			line-height: 60px;
			font-size: 30px;
			font-family: $mainFont;
			color: #07131f;
			text-shadow: 0px 2px 0px rgba(255, 255, 255, 1);
			-webkit-transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
		    transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
		}
		.responsive_btn span {
			position: absolute;
		}
		.responsive_btn:hover span {
			color: #152b45;
		}
}


.calculator__result {
	font-size: 24px;
	
	@include media-breakpoint-down(sm) {
		font-size: 14px;
	}

  .result {
    color: #fff;

    @include media-breakpoint-down(sm) {
      font-weight: 500;
    }
  }
}