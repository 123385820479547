.main_menu {
  list-style: none;
  margin: 0;
  padding-left: 0;

  @include media-breakpoint-up(md) {
    margin-top: 10px;
  }
}

.main_menu_item {
  position: relative;
  padding: 3px 10px 0 8px;
  z-index: 3;

  .main_menu_href {
    position: relative;
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    font-family: $additionalFont;
    white-space: nowrap;
    z-index: 5;
    
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      opacity: 1;
      color: #6DC7FF;
    }

    @include media-breakpoint-down(md) {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1.5px;
      //font-family: $mainFont;
    }
  }

  .redirectWhitepaper {
    a {
      font-size: 18px;
      line-height: 25px;
      color: rgb(255, 255, 255);
      font-family: $additionalFont;
      text-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
      text-decoration: none;
      padding: 5px;
      font-weight: 300;
      margin-right: 0;
      transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:hover{
      a{
        color: #6DC7FF;
        margin-right: 5px;
      }
    }

    .visions_link_icon {
      font-size: 21px;
      line-height: 7px;
      color: rgb(255, 255, 255);
      background-color: #7fbded;
    }

    @include media-breakpoint-down(md) {
      a {
        padding: 0 5px 0 16px;
        font-size: 20px;
        color: #ffffff;
        font-weight: 600;
        //font-family: $mainFont;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .dropdown__menu{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  &.active {
    .fa-caret-down { transform: rotate(180deg); }
  }
}

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: 10px 0 0 0;
  width: 362px;
  background: rgb(26,47,69);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0;
  overflow: hidden;
  transition: opacity .3s linear, visibility .3s linear;

  @include media-breakpoint-up(lg){
    overflow: visible;
    top: 130%;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: -40px;
      height: 40px;
      width: 100%;
      background-color: transparent;
      z-index: 1;
    }
  }

  @include media-breakpoint-down(md) {
    background: transparent;
  }

  &__item {
    &:first-child{
      position: relative;
      @include media-breakpoint-up(lg){
        &:after{
          content: " ";
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 10px solid rgb(26,47,69);
          position: absolute;
          top: -20px;
          left: 55px;
        }
      }
    }
    .main_menu_href {
      display: block;
      width: 100%;
      border: 2px solid transparent;
      padding: 8px 0 7px 15px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        padding: 3px 0 0 15px;
        color: rgba(#ffffff, .8);
        font-weight: 400;
        line-height: 1.1;
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          border: 2px solid #7dbbef;
          background: rgb(125,188,240);
          color: #1B3147;
          font-weight: 400;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    background-image: none;
    display: none;
    position: relative;
    padding-top: 0;
  }
}


// mobile menu
@media screen and (max-width: 768px) {
  .menu_container {
    & > .row {
      .flex_item {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .menu_container {
    width: 320px;
    position: absolute;
    right: -100%;
    padding: 0 20px;
    background-color: #051323;
    top: 50px;
    min-height: 65px;
    height: 100vh;
    max-height: 100vh;
    font-family: $mainFont;
    transition: right .4s;

    & > .row {
      justify-content: space-between;
      max-width: 750px;
      margin: 0 auto;
      align-items: center;
      height: 100%;
      display: block;
      flex-direction: column;

      .flex_item {
        justify-content: center;
      }

      .main_menu_wrapper {
        width: 100%;

        .main_menu {
          flex-direction: column;
          align-items: stretch;
          justify-content: center;

          .main_menu_item {
            padding: 3px 0;
            text-align: left;
          }
        }
      }
    }

    .menu-open  & {
      right: 0;
      box-shadow: none;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .menu_container {
    width: 100%;
  }
}

.menu_icon {
  display: block;
  text-align: right;
  position: absolute;
  right: 15px;
  top: -7px;
  width: 28px;
  height: 23px;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  transform: rotate(0deg);

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #7ab4e7;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }

  &--active span:nth-child(1) {
    top: 6px;
    width: 0;
    left: 50%;
  }

  &--active span:nth-child(2) {
    transform: rotate(45deg);
  }

  &--active span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &--active span:nth-child(4) {
    top: 6px;
    width: 0;
    left: 50%;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
