.inform {
  &__wrapper {
    position: relative;
    z-index: 9;
    padding: 15px 0 0 0;

    .orange_button {
      min-width: 300px;

      @include media-breakpoint-down(sm) {
        min-width: 250px;
      }
    }
  }

  &__text {
    font-size: 24px;
    line-height: 25px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: $additionalFont;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 45px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__hint {
    font-size: 13px;
    line-height: 25px;
    color: rgb(128, 189, 234);
    font-family: $additionalFont;
    text-align: center;
    text-transform: uppercase;

    & + .inform__hint {
      padding: 25px 0 35px 0;
      color: #fff;

      @include media-breakpoint-down(sm) {
        color: rgba(#ffffff, .8);
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 30px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
