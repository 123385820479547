// Colors

$blueBg: #21688A;
$whiteBg: #fff;
$greyBg: #132232;
$headerStatic: transparent;
$headerSticky:  url('../../img/assets/header.jpg') 100% 100%;;
$title: #256989;
$mainColor: #539CC2;
$secondaryColor: #7ab4e7;

// Fonts

$mainFont: 'Teko', sans-serif;
$additionalFont: 'Kanit', sans-serif;
$m__font: 'Montserrat', sans-serif;

// Constants
$header: 95px;
$section_height: 580px;



//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  0;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
$screen-sm:                  544px;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
$screen-md:                  768px;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
$screen-lg:                  992px;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

//
$screen-xl:                  1200px;
$screen-xl-min:              $screen-xl !default;
$screen-xl-desktop:          $screen-xl-min !default;

// Extra large screen / Extra wide desktop
$screen-xxl:                  1400px;
$screen-xxl-min:              $screen-xxl !default;
$screen-xxl-desktop:          $screen-xxl-min !default;

$grid-breakpoints: (
    xs: $screen-xs,
    sm: $screen-sm,
    md: $screen-md,
    lg: $screen-lg,
    xl: $screen-xl,
    xxl: $screen-xxl
);

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         34px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


//== Spacer
$spacer:   10px;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
    0: (
        x: 0,
        y: 0
    ),
    'half': (
        x: $spacer-x/2,
        y: $spacer-y/2
    ),
    1: (
        x: $spacer-x,
        y: $spacer-y
    ),
    'onehalf': (
        x: ($spacer-x * 1.5),
        y: ($spacer-y * 1.5)
    ),
    2: (
        x: ($spacer-x * 2),
        y: ($spacer-y * 2)
    ),
    3: (
        x: ($spacer-x * 3),
        y: ($spacer-y * 3)
    ),
    4: (
        x: ($spacer-x * 4),
        y: ($spacer-y * 4)
    )
);
