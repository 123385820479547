.absolute_errors {
  position: absolute !important;
  top: 10px;
  left: 105%;
  max-width: 300px;
  width: 100%;
  padding: 15px;
  background-color: #092f48;
  &:after {
    position: absolute;
    content: "";
    left: -8px;
    top: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent #063348 transparent transparent;
  }

  @include media-breakpoint-down(md) {
    top: calc(-100% - 12px);
    left: auto;
    right: 0;
    &:after {
      bottom: -12px;
      top: auto;
      transform: rotate(-90deg);
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(sm) {
    min-width: 275px;
    left: 0;
    margin: 0 auto;
  }

  .errors_list {
    list-style: none;
    padding: 0;
    margin: 0;

    .error__item {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      text-transform: uppercase;
      font-family: $additionalFont;

      .fa {
        margin-right: 10px;
        color: #e9483a;
      }

      & + .error__item { margin-top: 2px; }
    }
  }
}
