.slider-coverage{
  font-family: "Kanit",sans-serif;
  padding: 20px 50px 78px 50px;
  margin: 0 -15px;

  @include media-breakpoint-down(md) {
    padding: 20px 50px 78px 50px;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0 45px 0;
  }

  &__title{
    font-weight: 400;
    font-size: 24px;
    margin-top: 0;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 24px;
    }
    &_sm{
      font-size: 18px;
      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }
  }
  &__sub-title{
    font-weight: 300;
    font-size: 14px;
    font-style: italic;
    @include media-breakpoint-up(lg) {
      font-size: 13px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 14px;
    }
  }
  &__img{
    text-align: center;

    img{
      display: inline-block;
    }
  }
}



.slider-coverage{

  .slick-slide {
    img {
      width: auto;
      height: auto;
      // object-fit: cover;
    }
  }


  .slider_item {
    @include verticalGrad(#102d40, #142130);
    position: relative;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: auto;//min-height: 672px;
    height: 330px;
    // padding-bottom: 65px;

    @include media-breakpoint-up(sm) {
      min-height: auto;//min-height: 640px;
    }

    @include media-breakpoint-up(lg) {
      height: 300px;
    }
    @include media-breakpoint-up(xl) {
      height: 330px;
      min-height: auto;//min-height: 586px;
      max-width: none;//max-width: 265px;
    }

    @include media-breakpoint-down(sm) {
      max-width: none;//max-width: 95%;
      width: 330px;
      margin-right: 20px;
    }

    @include media-breakpoint-down(xs) {
      width: 280px;
      background: linear-gradient(to bottom, #102d40 0%, rgba(21, 30, 41, 0.8196078431372549) 100%);
    }

  }

  .slider_item_content{
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include media-breakpoint-up(lg) {
       height: 140px;
    }
    @include media-breakpoint-up(xl) {
      height: 120px;
    }
  }

  .slick-disabled{
    visibility: hidden;
    opacity: 0;
  }

  .slider_item .more_button,
  .slider_item .more_button:active,
  .slider_item .more_button:focus {
    @include media-breakpoint-down(sm) {
      left: calc(50% - 75px);
      width: 150px;
      font-weight: 700;
      bottom: 10px;
    }
  }

}

