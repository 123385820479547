.term-title {
  margin-top: 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    letter-spacing: 1px;
    font-weight: 600;
  }
}

.term-description {
  color: #ffffff;
  font-family: $additionalFont;
  font-size: 18px;
  font-weight: 300;
  padding-left: 15px;
  border-left: 2px solid #7ebce9;
  margin-bottom: 60px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 30px;
  }
}