.design__blocks {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/header/blured.jpg);
  background-size: 100% 100%;

  @include media-breakpoint-down(md) {
    display: none;
  }
}


.stars{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 574px;
  background: url(../img/header/stars.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 9;
}
.filter{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: url(../img/header/filter.png);
  z-index: 9;
}
.curcuit{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 501px;
  background: url(../img/header/curcuit.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9;
}

#header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  object-fit: cover;
  z-index: 9;
}
#stars{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: 99;
}