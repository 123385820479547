.main_btn:hover .cls-3 {
	opacity: 1;
}

.main_btn:hover .a {
	fill: #aad9f9;
}

.main_btn:active .b {
	fill: white;
}
.main_btn span {
	position: absolute;
	width: 225px;
	text-align: center;
	line-height: 80px;
	font-size: 48px;
	font-family: 'Teko', sans-serif;
	color: #07131f;
	text-shadow: 0px 2px 0px rgba(255, 255, 255, 1);
	-webkit-transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-weight: 500;
}
.main_btn:hover span {
	color: #152b45;
}
.cls-1, .cls-3 {
  fill: #7fbded;
  -webkit-transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cls-3 {
  fill: white;
  -webkit-transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cls-2 {
  // fill: url(#linear-gradient);
  fill: white;
}

.cls-3 {
  opacity: 0;
  -webkit-transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.main_btn{
  position: relative;
  transition: all 0.1s linear;
	display: inline-block;
	&:hover, &.pulse_btn{
		transform: scale(1.05);
	}
	&.openModal{
		text-decoration: none;
    border-bottom: none;
	}
}
.orange_button {
    padding: 11px 17px;
    text-transform: uppercase;
    color: rgb(7,19,31);
    font-size: 30px;
    font-family: $mainFont;
    background: url(../img/assets/buttons/big_shadow_center.png);
    background-size: 100% 100%;
    background-repeat: repeat-x;
    position: relative;
    &:before{
      content: ' ';
      width: 30px;
      position: absolute;
      left: -30px;
      top: 0;
      display: inline-block;
      height: 100%;
      background: url(../img/assets/buttons/big_shadow_left.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &:after{
      content: ' ';
      width: 30px;
      position: absolute;
      right: -30px;
      top: 0;
      display: inline-block;
      height: 100%;
      background: url(../img/assets/buttons/big_shadow_right.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &:focus, &:hover, &:visited, &:active{
      background: url(../img/assets/buttons/big_shadow_center.png);
      background-size: 100% 100%;
      background-repeat: repeat-x;
      color: rgb(7,19,31);
      outline: none;
    }

  @media (max-width: 480px) {
    font-size: 24px;
    letter-spacing: 2px;
    width: calc(100% - 30px);
  }
}


.blue_button {
  font-size: 30px;
  letter-spacing: 2px;
  color: rgb(128,189,234);
  font-family: $mainFont;
  text-align: center;
  border: 2px solid #7bb5e1;
  border-radius: 0;
  background: transparent;
  padding: 4px 0 0 0;
  max-width: 270px !important;
  margin-left: 30px;
  position: relative;
  &:focus, &:hover, &:visited, &:active{
    background-color: #132232;
    color: rgb(128,189,234);
    outline: none;
  }

  @include media-breakpoint-down(sm) {
    font-size: 24px;
    max-width: 290px;
    margin: 0 auto;
    padding: 10px 0 7px 0;
    font-weight: 600;
  }
}

.red_button{
    @include verticalGrad(#CE7170, #904E4D);
    padding: 12px 17px;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-family: $additionalFont;
    border-radius: 0;
    border: 0;
    &:hover, &:focus{
        color: #fff;
        @include verticalGrad(#904E4D, #CE7170);
    }
}
.white_button{
    border-radius: 0;
    background: transparent;
    border: 2px solid #fff;
    font-size: 16px;
    font-family: $additionalFont;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 40px;
    transition: all linear 0.1s;
    &:hover, &:focus{
        color: #3a76a0;
        background: #fff;
    }
}

.more_button,
.more_button:active,
.more_button:focus,
.more_button:hover{
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 20px;
    color: rgb(255,255,255);
    font-family: $mainFont;
    padding: 13px 0;
    border-radius: 0;
    font-weight: 300;
		.left__button__corner,
		.right__button__corner{
			border-right-color: #fff;
		}
}

.left__button__corner{
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid #7bb5e1;
  border-bottom: 15px solid transparent;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(45deg);
  position: absolute !important;
  left: -4px;
  top: -11px;
  .corner__inner{
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -15px;
      left: -2px;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid #122232;
      border-bottom: 15px solid transparent;
    }

    .scroll__btn--section & {
      &:after {
        border-right: 15px solid #051323;
      }
    }
  }
}

.right__button__corner{
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid #7bb5e1;
  border-bottom: 15px solid transparent;
  transform: rotate(225deg);
  position: absolute !important;
  right: -4px;
  top: calc(100% - 19px);

  .corner__inner{
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -15px;
      left: -2px;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid #122232;
      border-bottom: 15px solid transparent;
    }

    .scroll__btn--section & {
      &:after {
        border-right: 15px solid #051323;
      }
    }
  }
}

.triangle__blue {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-left: 9px solid transparent;
  border-right: 9px solid #7bb5e1;
  position: absolute !important;
  top: -4px;
  left: -4px;
  transform: rotate(180deg);

  @include media-breakpoint-down(sm) {
    display: none;
  }
}


.scroll__btn {
  min-width: 142px;
  min-height: 35px;
  font-size: 18px;
  line-height: 25px;
  color: #7EBCEC;
  font-family: $additionalFont;
  background-image: url(../img/buttons/scroll_bg.png);
  background-size: 100% 100%;
  background-color: transparent;
  border: none;
  outline: none;

  span {
		display: block;
	}

	.fa-caret-up{
		position: absolute;
		top: auto;
		bottom: 100%;
	}
	.fa-caret-down{
		position: absolute;
		top: 100%;
		bottom: auto;
	}

  &:focus,
  &:hover,
  &:visited,
  &:active {
    background-color: transparent;
    // border: none;
    outline: none;
    color: rgb(255,255,255);
  }

  &--section {
    @include media-breakpoint-down(sm) {
      min-width: auto;
      max-width: 300px;
      width: 100%;
      line-height: 32px;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 2px;
      color: rgba(108, 164, 209, 0.9);
      background-image: none;
      border: 2px solid rgba(108, 164, 209, 0.9);

      .fa { display: none; }

      &:hover {
        border: 2px solid rgba(108, 164, 209, 0.9);
      }
    }
  }
}


button.blue_button{
  background-image: url('../img/buttons/blue_button.png');
  background-size: 100% 100%;
  border: none !important;
}

.responsive_btn {
  position: relative;
  white-space: nowrap;
	display: inline-block;
  background: transparent;
  border: 0;

	&+.blue_button{
		height: 60px;
		text-transform: uppercase;
		margin-top: 0 !important;
	}
	&.red{
    pointer-events: none;
	}
  &.red-pe_all{
    pointer-events: all;
  }
	.currency{
		color: #3f5268;
		font-size: 24px;
		font-style: normal;
	}
}

.responsive_btn .cls-1 {
		 fill: #7fbded;
		 -webkit-transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	 transition: fill 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	 }
	.responsive_btn .cls-2 {
		 // fill: url(#linear-gradient);
     fill: white;
	 }
	.responsive_btn .cls-3 {
		 fill: #fff;
		 fill-opacity: 0;
		 -webkit-transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	 transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	 }
.responsive_btn:hover .cls-3 {
	opacity: 1;
}
.responsive_btn:hover .a {
	fill: #aad9f9;
}
.responsive_btn:active .b {
	fill: white;
}
li.active{
	.responsive_btn .b {
		fill: white;
	}
}

.responsive_btn.red .a {
	fill: #ed7f7f;
}
.responsive_btn.red .b {
	fill: #973535;
}
.responsive_btn.red span {
	color: #bdbdbd !important;
}
.responsive_btn.red.red-pe_all span{
  color: #973535 !important;
}
.responsive_btn.yellow .a {
	fill: #f2d8a5;
}
.responsive_btn.yellow .b {
	fill: #dbc56e;
}
.responsive_btn.yellow span {
	color: #bdbdbd !important;
}

.responsive_btn.green .a {
	fill: #acd19e;
}
.responsive_btn.green:hover .a {
	fill: #b7dda9;
}
.responsive_btn.green .b {
	fill: #46b250;
}
.responsive_btn.green:active .b {
	fill: white;
}

.responsive_btn span {
	text-align: center;
	line-height: 60px;
	font-size: 30px;
	font-family: 'Teko', sans-serif;
	color: #07131f;
	text-shadow: 0px 2px 0px rgba(255, 255, 255, 1);
	-webkit-transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
		transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	&+svg{
		margin-left: -1px;
		margin-right: -1px;
	}
}
.responsive_btn span {
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 99;
	cursor: pointer;
}
.responsive_btn:hover span {
	color: #152b45;
}
.nav-tabs>li>a.responsive_btn{
	display: inline-block;
	padding: 0;
}
.nav-tabs>li.active>a.responsive_btn,
.nav-tabs>li>a.responsive_btn,
.nav-tabs>li>a.responsive_btn:hover
.nav-tabs>li.active>a.responsive_btn:hover{
	background-color: transparent !important;
	border: none !important;
}

.btn_instruction {
  @include media-breakpoint-down(xs) {
    width: calc(100% - 30px);
    font-weight: 600;
  }
}


.btn--play {
  width: 120px;
  height: 120px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 50;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 0 60px 120px;
    border-color: transparent transparent transparent #79b2ee;
    transition: border .3s;

    // width: 100%;
    // height: 100%;
    // background-image: url('../img/assets/play-btn.png');
    // background-repeat: no-repeat;
    // background-size: contain;
  }

  &:hover {
    &:after {
      border-color: transparent transparent transparent darken(#79b2ee, 10%);
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(md) {
    width: 110px;
    height: 110px;
    &:after {
      border-width: 60px 0 60px 110px;
    }
  }

  @include media-breakpoint-down(sm) {
    top: 45%;
  }
}
.buttons__square{
	@include flexible(space-between, center, row);
	@media screen and (max-width: 768px){
		justify-content: center;
	}
	flex-wrap: wrap;
	padding-top: 10px;
	.responsive_btn{
		margin-top: 15px;
	}
}
.btn-big-blue{
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
  font-family: $mainFont;
  color: white;
  text-align: center;
  background: #092f48;
  padding: 1px 20px;
}

.back__btn{
	position: relative;
  width: 90px;
  padding: 1px 6px;
	@include media-breakpoint-down(sm) {
  	display: none;
  }
	&:before{
		content: " ";
		display: inline-block;
		width: 0;
		height: 0;
		border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
		border-right: 15px solid #092f48;
		position: absolute;
		top: 0;
		left: -15px;
	}
}




.btn{

  //color
  &_white-transp{
    background-color: white;
    transition: color 0.25s, border-color 0.25s, background-color 0.25s;
    &:hover{
      color: white;
      border-color: white;
      background-color: transparent;
    }
  }

  // width
  &_mw130{
    min-width: 130px;
  }
}